import { isObject, isDefined } from "../lib/utils";
import { NOT_DEFINED } from "./constants";
import { parse, generateBasicTemplate } from "../lib/parse";
import {
  getRelativesForUid,
  getUidAndDescendantsForUid,
  getCurrentBreakpointFromWidth,
  saferGet,
} from "../lib/utils";

export const getUid = (state) => {
  return state.currentEdit;
};

export const getContent = (state) => {
  return state.content;
};

export const getRelatives = (state) => {
  const uid = getUid(state);
  const content = getContent(state);
  const relatives = getRelativesForUid({ tree: content, uid });
  return relatives;
};

export const getUidAndDescendants = (state) => {
  const uid = getUid(state);
  const content = getContent(state);
  const uidAndDescendants = getUidAndDescendantsForUid({ tree: content, uid });
  return uidAndDescendants;
};

export const getCurrentItemContent = (state) => {
  const uid = getUid(state);
  const content = getContent(state);
  return content[uid] || {};
};

export const getItemContent = (state, { uid }) => {
  const content = getContent(state);
  return content[uid] || {};
};

export const getEditorMode = (state) => {
  return state.editorMode;
};

export const getTheme = (state) => {
  return state.theme;
};

export const getThemeDefaultStyles = (state) => {
  const theme = getTheme(state);
  return theme.styles;
};

// ultimately, we want to return an array of options
export const getOptionsForThemeKey = (state, { themeKey }) => {
  const theme = getTheme(state);
  const themeValues = theme[themeKey] || {};
  if (Array.isArray(themeValues)) {
    return themeValues;
  } else if (isObject(themeValues)) {
    // dealing with an object.
    // in event of colors, we want the entire object returned
    if (themeKey === "colors") {
      return themeValues;
    }
    return Object.keys(themeValues);
  }
  return themeValues;
};

export const getThemeDefaultStylesForType = (state, { type }) => {
  const styles = getThemeDefaultStyles(state);
  return styles[type] || {};
};

export const getDefaultPropValuesForItem = (state, { name, uid }) => {
  const itemUid = uid || getUid(state);
  const { type } = getItemContent(state, { uid: itemUid });
  const defaultTypeStyles = state.theme.styles[type] || {};
  const defaultValues = defaultTypeStyles[name];
  return defaultValues || NOT_DEFINED;
};

export const getDefinedPropValuesForItem = (state, { name, uid }) => {
  const itemUid = uid || getUid(state);
  const { props = {} } = getItemContent(state, { uid: itemUid });
  const definedValues = saferGet(props, name, null);
  return definedValues || NOT_DEFINED;
};

export const getCurrentPropValuesForItem = (state, { name, uid }) => {
  const definedValue = getDefinedPropValuesForItem(state, { name, uid });
  const defaultValue = getDefaultPropValuesForItem(state, { name, uid });

  if (isDefined(definedValue)) return definedValue;
  if (isDefined(defaultValue)) return defaultValue;

  return [];
};

export const getFormattedMarkup = (state) => {
  const content = getContent(state);
  const theme = getTheme(state);
  const formattedMarkup = generateBasicTemplate(
    parse({ input: content, theme }),
    theme
  );
  return formattedMarkup;
};

export const getPreviewWidth = (state) => {
  return state.previewWidth;
};

export const getActiveBreakpoint = (state) => {
  const width = getPreviewWidth(state);
  const theme = getTheme(state);
  const { breakpoints } = theme;
  return getCurrentBreakpointFromWidth(width, breakpoints);
};

export const getFocusedProp = (state) => {
  return state.focusedProp;
};
