import React from "react";
import { connect } from "react-redux";
import { InViewAnimation } from "@mknudsen01/superblock";
import { getItemContent } from "../redux/selectors";

const Animation = ({
  children,
  threshold = 0.3,
  triggerOnce = true,
  delay = 0.3,
  y = 20,
}) => {
  return (
    <InViewAnimation
      threshold={threshold}
      triggerOnce={triggerOnce}
      delay={delay}
      y={y}
    >
      {children}
    </InViewAnimation>
  );
};

// TODO - add some default props in theme? Or maybe defaults just to the prop?
const mapStateToProps = (state, ownProps) => {
  const animation = getItemContent(state, { uid: ownProps.uid });
  const { threshold, y, delay, triggerOnce } = animation.props;

  return {
    threshold,
    y,
    delay,
    triggerOnce,
  };
};

export default connect(mapStateToProps, {})(Animation);
