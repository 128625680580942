import React, { forwardRef } from "react";
import { connect } from "react-redux";
import { Image } from "@mknudsen01/superblock";
import { getItemContent } from "../redux/selectors";

const ConnectedImage = forwardRef(
  ({ imageProps, className, onClick, ...rest }, ref) => {
    return (
      <Image
        ref={ref}
        {...rest}
        className={className}
        onClick={onClick}
        {...imageProps}
      />
    );
  }
);

const mapStateToProps = (state, ownProps) => {
  const { props } = getItemContent(state, { uid: ownProps.uid });
  const {
    smallSrc,
    src,
    alt,
    imgHeight,
    imgWidth,
    aspectRatio,
    imagePositionY,
  } = props;

  return {
    imageProps: {
      aspectRatio,
      smallSrc,
      src,
      alt,
      imgHeight,
      imgWidth,
      imagePositionY,
    },
  };
};

export default connect(mapStateToProps, {}, null, { forwardRef: true })(
  ConnectedImage
);
