import styled from "styled-components";

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  transition: 0.3s max-height ease;
  overflow: hidden;
  padding-top: 10px;
  max-height: ${(props) => (props.isHidden ? "0" : "30px")};
`;

export default Footer;
