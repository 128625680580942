import { SET_FOCUSED_PROP } from "../actionTypes";

export default function (state = "", action = {}) {
  switch (action.type) {
    case SET_FOCUSED_PROP: {
      const { name } = action.payload;

      return name;
    }
    default:
      return state;
  }
}
