import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getUid, getItemContent } from "../redux/selectors";
import PropUpdaterController from "./ContentUpdaterController";
import { deleteAnimation, addAnimation } from "../redux/actions";

const Delete = styled.button`
  background: white;
  color: ${(props) => props.theme.colors["red"][5]};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors["red"][5]};
  border-radius: 5px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors["red"][5]};
    color: white;
  }
`;

function AnimationController({
  animation,
  parentId,
  deleteAnimation,
  addAnimation,
}) {
  const { uid, props } = animation;
  const hasAnimation = !!animation.uid;

  return (
    <>
      {!!hasAnimation && (
        <>
          <p>Animation: </p>
          {props &&
            Object.keys(props).map((propName) => {
              return (
                <PropUpdaterController
                  key={propName}
                  uid={uid}
                  name={propName}
                />
              );
            })}
          <Delete
            onClick={() => deleteAnimation({ animationId: uid, parentId })}
          >
            Delete Animation
          </Delete>
        </>
      )}
      {!hasAnimation && (
        <button onClick={() => addAnimation({ parentId })}>
          Add an animation
        </button>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const uid = getUid(state);
  const content = getItemContent(state, { uid });
  const animation = getItemContent(state, { uid: content.animation });

  return {
    parentId: uid,
    animation,
  };
};

export default connect(mapStateToProps, { deleteAnimation, addAnimation })(
  AnimationController
);
