import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { addItem } from "../redux/actions";
import { getUid, getItemContent } from "../redux/selectors";
import { CONTENT_TYPES, TYPE_TO_DISPLAY_NAME } from "../redux/constants";

const Controls = styled.div`
  max-height: ${(props) => (props.isVisible ? "300px" : 0)};
  overflow: ${(props) => (props.isVisible ? "auto" : "hidden")};
  transition: max-height 0.3s ease-in-out;
`;

const Button = styled.button`
  font-weight: bold;
`;

const List = styled.ul`
  max-height: ${(props) => (props.isOpen ? "200px" : 0)};
  overflow: ${(props) => (props.isOpen ? "auto" : "hidden")};
  transition: max-height 0.3s ease-in-out;
`;

function AddItemController({ children, addItem, isImage }) {
  const [isOpen, setIsOpen] = useState(false);
  const isVisible = !isImage && Array.isArray(children);
  return (
    <Controls isVisible={isVisible}>
      <Button
        disabled={!isVisible}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        Add new item
      </Button>
      <List isOpen={isOpen}>
        {Object.values(CONTENT_TYPES).map((type) => (
          <li
            key={type}
            onClick={async () => {
              if (!isVisible) return null;
              await addItem({ type });
              setIsOpen(false);
            }}
          >
            Add {TYPE_TO_DISPLAY_NAME[type]}
          </li>
        ))}
      </List>
    </Controls>
  );
}

const mapStateToProps = (state) => {
  const uid = getUid(state);
  const { children, type } = getItemContent(state, { uid });
  return {
    children,
    isImage: type === CONTENT_TYPES.IMAGE,
  };
};

export default connect(mapStateToProps, { addItem })(AddItemController);
