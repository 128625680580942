import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { connect } from "react-redux";
import unique from "lodash.uniq";
import TreeItem from "./TreeItem";
import { buildExpandedItemsToId, buildVisibleItems } from "../lib/utils";
import { getContent, getUid } from "../redux/selectors";
import { TYPE_TO_DISPLAY_NAME } from "../redux/constants";

export const TreeViewContext = React.createContext({});

if (process.env.NODE_ENV !== "production") {
  TreeViewContext.displayName = "TreeViewContext";
}

const StyledTreeView = styled.div`
  max-height: 500px;
  /* background-color: ${(props) => props.theme.colors["blue"][0]}; */
  overflow: auto;
`;

const TreeView = (props) => {
  const { data, activeUid } = props;
  const [focusedUid, setFocusedUid] = useState(activeUid || "root");
  const [expandedItems, setExpandedItems] = useState(
    buildExpandedItemsToId(data, activeUid)
  );
  const visibleItems = useRef([]);

  useEffect(() => {
    setExpandedItems((existingExpanded) =>
      unique([...existingExpanded, ...buildExpandedItemsToId(data, activeUid)])
    );
  }, [data, activeUid]);

  useEffect(() => {
    visibleItems.current = buildVisibleItems(data, expandedItems, activeUid);
  }, [data, expandedItems, activeUid]);

  useEffect(() => {
    setFocusedUid(activeUid);
  }, [activeUid]);

  const focusNextItem = () => {
    const items = visibleItems.current;
    const currentIndex = items.findIndex((item) => item.uid === focusedUid);
    if (currentIndex >= items.length - 1) return; // last one!

    const nextUid = items[currentIndex + 1].uid;
    setFocusedUid(nextUid);
  };

  const focusSearchTerm = (input) => {
    const sanitizedInput = input.toLowerCase();
    const items = visibleItems.current;
    const currentIndex = items.findIndex((item) => item.uid === focusedUid);
    for (let i = 1; i < items.length; i++) {
      const indexToCheck = (currentIndex + i) % items.length;
      const currentItem = items[indexToCheck];
      const { type: unsanitizedType, uid } = currentItem;
      const type = TYPE_TO_DISPLAY_NAME[unsanitizedType];
      const firstLetter = type[0].toLowerCase();

      if (firstLetter === sanitizedInput) {
        setFocusedUid(uid);
        return;
      }
    }
  };

  const focusParentItem = (uid) => {
    const items = visibleItems.current;
    const parent = items.find(
      (item) => item.children && item.children.includes(uid)
    );
    if (!parent) return;
    setFocusedUid(parent.uid);
  };

  const expandItem = (uid) => {
    setExpandedItems([...expandedItems, uid]);
  };

  const expandSiblings = (uid) => {
    const items = visibleItems.current;
    let siblings;
    const parent = items.find(
      (item) => item.children && item.children.includes(uid)
    );
    if (!parent) {
      siblings = [uid];
    } else {
      siblings = parent.children;
    }
    const newExpandedItems = [...expandedItems];
    siblings.forEach((childId) => {
      if (!newExpandedItems.includes(childId)) {
        newExpandedItems.push(childId);
      }
    });
    setExpandedItems(newExpandedItems);
  };

  const collapseItem = (uid) => {
    setExpandedItems([...expandedItems.filter((item) => item !== uid)]);
  };

  const focusItem = (uid) => {
    setFocusedUid(uid);
  };

  const focusFirstItem = () => {
    const items = visibleItems.current;
    setFocusedUid(items[0].uid);
  };
  const focusLastItem = () => {
    const items = visibleItems.current;
    setFocusedUid(items[items.length - 1].uid);
  };

  const focusPreviousItem = () => {
    const items = visibleItems.current;
    const currentIndex = items.findIndex((item) => item.uid === focusedUid);
    if (currentIndex === 0) return; // last one!

    const nextUid = items[currentIndex - 1].uid;
    setFocusedUid(nextUid);
  };

  const renderTree = (uid, nodes, level = 0) => {
    const node = nodes[uid] || {};
    const currentLevel = level;
    const isTextElement = !!node.children && !Array.isArray(node.children);
    const hasAnimation = !!node.animation;

    const typeLabel = TYPE_TO_DISPLAY_NAME[node.type];

    const animationPrefix = hasAnimation ? "*" : "";
    const label = isTextElement
      ? `${animationPrefix} ${typeLabel} - ${node.children}`
      : `${animationPrefix}${typeLabel}`;
    return (
      <TreeItem
        key={node.uid}
        uid={node.uid}
        label={label}
        level={currentLevel}
      >
        {Array.isArray(node.children) && !!node.children.length
          ? node.children.map((childId) =>
              renderTree(childId, nodes, currentLevel + 1)
            )
          : null}
      </TreeItem>
    );
  };

  const providedValues = {
    activeUid,
    focusedUid,
    focusFirstItem,
    focusLastItem,
    focusNextItem,
    focusSearchTerm,
    focusItem,
    focusPreviousItem,
    focusParentItem,
    expandedItems,
    expandItem,
    expandSiblings,
    collapseItem,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <TreeViewContext.Provider value={providedValues}>
        <StyledTreeView>
          <ul role="tree">{renderTree("root", data)}</ul>
        </StyledTreeView>
      </TreeViewContext.Provider>
    </DndProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    data: getContent(state),
    activeUid: getUid(state),
  };
};

export default connect(mapStateToProps, {})(TreeView);
