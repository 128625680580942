import React, { useState } from "react";
import styled from "styled-components";
import Highlight, { defaultProps } from "prism-react-renderer";
import Tooltip from "@material-ui/core/Tooltip";
import ClipboardIcon from "./icons/Clipboard";
import theme from "prism-react-renderer/themes/github";

const Pre = styled.pre`
  padding: 20px;
  overflow: auto;
  border-radius: 5px;
  box-shadow: ${(props) => props.theme.shadows[3]};
`;

const CopyIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
`;

const HiddenText = styled.textarea`
  display: none;
`;

const MarkupDisplay = ({ children, language }) => {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const content = React.useRef(null);

  return (
    <Container>
      <HiddenText ref={content} value={children} />
      <Tooltip
        title="Copied!"
        onOpen={() => {
          setTimeout(() => {
            setShowCopySuccess(false);
          }, 2000);
        }}
        open={showCopySuccess}
      >
        <CopyIcon
          onClick={async () => {
            try {
              await navigator.clipboard.writeText(children);
              setShowCopySuccess(true);
            } catch (error) {}
          }}
        >
          <ClipboardIcon />
        </CopyIcon>
      </Tooltip>
      <Highlight
        {...defaultProps}
        code={children}
        language={language || "jsx"}
        theme={theme}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <Pre className={className} style={style}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    </Container>
  );
};

export default MarkupDisplay;
