import React, { useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import { connect } from "react-redux";
import ContentList from "./ContentList";
import { P, Box, Flex, Heading } from "@mknudsen01/superblock";
import { getValueForBreakpoint } from "../lib/utils";
import { FrameContext } from "react-frame-component";
import InViewAnimation from "./ConnectedInViewAnimation";
import StylesWhenEditable, {
  ImageWhenEditable,
} from "./styles/StylesWhenEditable";
import Image from "./ConnectedImage";
import { PROPS, EDITOR_MODES, CONTENT_TYPES } from "../redux/constants";
import { setCurrentEdit } from "../redux/actions";
import {
  getItemContent,
  getEditorMode,
  getUid,
  getThemeDefaultStylesForType,
  getActiveBreakpoint,
} from "../redux/selectors";

const COMPONENTS_BY_TYPE = {
  [CONTENT_TYPES.P]: P,
  [CONTENT_TYPES.BOX]: Box,
  [CONTENT_TYPES.ROOT]: Box,
  [CONTENT_TYPES.FLEX]: Flex,
  [CONTENT_TYPES.HEADING]: Heading,
  [CONTENT_TYPES.IMAGE]: Image,
};

// const ANIMATION_COMPONENTS_BY_TYPE = {
//   [CONTENT_TYPES.IN_VIEW_ANIMATION]: InViewAnimation,
// };

const EmptyItemStyles = styled.div`
  /* background: fuchsia; */
  /* height: 20px; */
  width: 100%;
`;

// const withAnimations = (BaseComponent, animation) => {
//   if (!animation) {
//     return <BaseComponent />;
//   } else {
//     const AnimationWrapper = ANIMATION_COMPONENTS_BY_TYPE[animation.type];
//     return (
//       <AnimationWrapper key={animation.uid} {...animation.props}>
//         <BaseComponent />
//       </AnimationWrapper>
//     );
//   }
// };

const ChildlessItem = () => (
  <EmptyItemStyles>
    <P>Add to me!</P>
  </EmptyItemStyles>
);

const ContentItem = ({
  type,
  uid,
  props,
  children,
  defaultStyles,
  isEditing,
  setCurrentEdit,
  customHoverDuration,
  isCurrentEdit,
  animation,
}) => {
  const Component = COMPONENTS_BY_TYPE[type] || type;
  const isTextElement = !Array.isArray(children);
  const isImage = type === CONTENT_TYPES.IMAGE;
  const isEmpty = Array.isArray(children) && children.length === 0;
  const [ref, inView, entry] = useInView();
  const { window: frameWindow } = useContext(FrameContext);

  /**
   * this feels a little hacky. what's going on!
   * Well, we need to have the isCurrentEdit as a dependency of the scrolling effect
   * However, if we're checking isCurrentEdit && !inView to scroll the element into
   * the screen (when it's clicked in the sidebar), then _any_ time inView changes,
   * like when the user is scrolling the page markup, this effect will fire again and
   * scroll the element back into view. That ends up locking the user from being able
   * to scroll away from the active edit, which feels pretty not good.
   * wasCurrentEditRef will change _only_ when isCurrentEdit changes. which ends up
   * meaning that inView can change all it wants - as long as the active element
   * wasn't most recently out of the screen, it won't lock the user to scrolling
   * back into view.
   */
  const wasCurrentEditRef = useRef(isCurrentEdit);
  useEffect(() => {
    wasCurrentEditRef.current = isCurrentEdit;
  }, [isCurrentEdit]);
  const wasCurrentEdit = wasCurrentEditRef.current;

  // scroll the current edit into the iframe if it's not inView
  useEffect(() => {
    if (!wasCurrentEdit && isCurrentEdit && !inView) {
      if (
        !frameWindow ||
        !frameWindow.scrollTo ||
        !entry ||
        !entry.boundingClientRect
      )
        return;
      frameWindow.scrollTo({
        left: 0,
        top: entry.boundingClientRect.top,
        behavior: "smooth",
      });
    }
    return () => {};
  }, [wasCurrentEdit, isCurrentEdit, inView, entry, frameWindow]);

  const handleClick = (e, uid) => {
    if (uid === "root") return;
    e.preventDefault();
    e.stopPropagation();
    setCurrentEdit(uid);
  };

  const ComponentToRender = isEditing
    ? type === CONTENT_TYPES.IMAGE
      ? ImageWhenEditable
      : StylesWhenEditable
    : Component;

  const propsWhenEditing = {
    as: Component,
    isCurrentEdit,
    customHoverDuration,
    onClick: (e) => {
      handleClick(e, uid);
      // this blurs the element immediately so that hotkeys are still available
      document.activeElement.blur();
    },
  };

  const fullProps = {
    uid,
    ref,
    onClick: (e) => document.activeElement.blur(),
    ...(isEditing && { ...propsWhenEditing }),
    ...defaultStyles,
    ...props,
  };

  const actualChildren = isTextElement ? (
    children
  ) : isEmpty ? (
    <ChildlessItem />
  ) : isImage ? null : (
    <ContentList childIds={children} />
  );

  if (animation && animation.type === CONTENT_TYPES.IN_VIEW_ANIMATION) {
    return (
      <InViewAnimation uid={animation.uid}>
        <ComponentToRender key={uid} {...fullProps}>
          {actualChildren}
        </ComponentToRender>
      </InViewAnimation>
    );
  }

  return (
    <ComponentToRender key={uid} {...fullProps}>
      {actualChildren}
    </ComponentToRender>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { type, props, children, animation: animationId } = getItemContent(
    state,
    {
      uid: ownProps.uid,
    }
  );

  const { hover: hoverProps = {}, ...rest } = props;
  const breakpoint = getActiveBreakpoint(state);

  const hover = Object.keys(hoverProps).reduce((acc, propName) => {
    return {
      ...acc,
      [propName]: getValueForBreakpoint(hoverProps[propName], breakpoint),
    };
  }, {});

  const currentEdit = getUid(state);

  const defaultStyles = getThemeDefaultStylesForType(state, { type });
  const isEditing = getEditorMode(state) === EDITOR_MODES.EDITING;
  const animation = animationId
    ? getItemContent(state, {
        uid: animationId,
      })
    : null;

  return {
    type,
    props: { ...rest, hover },
    customHoverDuration: hover && hover[PROPS.TRANSITION_DURATION],
    children,
    defaultStyles,
    isEditing,
    isCurrentEdit: ownProps.uid === currentEdit,
    animation,
  };
};

export default connect(mapStateToProps, { setCurrentEdit })(ContentItem);
