import React from "react";
import { connect } from "react-redux";
import { useHotkeys } from "react-hotkeys-hook";
import { ThemeProvider } from "styled-components";
import ContentItem from "./ContentItem";
import EditingPane from "./EditingPane";
import PreviewFrame from "./PreviewFrame";
import Slider from "./styles/Slider";
import PageControls from "./PageControls";
import {
  getTheme,
  getFormattedMarkup,
  getEditorMode,
  getPreviewWidth,
  getActiveBreakpoint,
} from "../redux/selectors";
import {
  setViewMode,
  setPreviewWidth,
  clearCurrentEdit,
} from "../redux/actions";

import {
  BreakpointControls,
  StyledButtons,
  StyledButton,
  BreakpointControlDisplay,
} from "./BreakpointControls";
import {
  BREAKPOINT_NAMES,
  MIN_WIDTH,
  WIDTH_STEP,
  EDITOR_MODES,
} from "../redux/constants";
import { getBreakpointInPixels } from "../lib/utils";
import ExportModeDisplay from "./ExportModeDisplay";

const isTreeItemFocused = () => {
  return (
    document.activeElement &&
    document.activeElement.attributes &&
    document.activeElement.attributes.role &&
    document.activeElement.attributes.role.value === "treeitem"
  );
};

const PageContent = ({
  isLoading,
  theme,
  formattedMarkup,
  viewMode,
  setViewMode,
  setWidth,
  width,
  activeBreakpoint,
  clearCurrentEdit,
}) => {
  const { breakpoints } = theme;

  useHotkeys("p", () => {
    if (isTreeItemFocused()) return;
    setViewMode(EDITOR_MODES.PREVIEW);
  });
  useHotkeys("e", () => {
    if (isTreeItemFocused()) return;
    setViewMode(EDITOR_MODES.EDITING);
  });
  useHotkeys("c", () => {
    if (isTreeItemFocused()) return;
    setViewMode(EDITOR_MODES.MARKUP);
  });
  useHotkeys("esc", () => {
    if (isTreeItemFocused()) return;
    clearCurrentEdit();
    // document.activeElement && document.activeElement.blur();
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{ position: "relative", overflow: "hidden" }}>
        <PageControls viewMode={viewMode} setViewMode={setViewMode} />
        <div style={{ minHeight: "100vh" }}>
          <EditingPane canEdit={viewMode === EDITOR_MODES.EDITING} />
          {/**
           * TODO: needs to be more clear what the breakpoints actually mean to the end user.
           * should clicking take you to the minimum width in the breakpoint? maximum?
           */}
          <BreakpointControls>
            <StyledButtons>
              {breakpoints.map((breakpoint, index) => {
                const width = getBreakpointInPixels(breakpoint);
                return (
                  <StyledButton
                    key={`button-${index}`}
                    onClick={() => setWidth(width - WIDTH_STEP)}
                    isActive={activeBreakpoint === index}
                  >
                    {BREAKPOINT_NAMES[index]}
                  </StyledButton>
                );
              })}
            </StyledButtons>
            <Slider
              hasValue
              name="width"
              type="range"
              min={MIN_WIDTH}
              max={window.innerWidth}
              step={WIDTH_STEP}
              onChange={(e) => setWidth(parseInt(e.target.value, 10))}
              value={width}
            />
            <BreakpointControlDisplay>{width}px</BreakpointControlDisplay>
          </BreakpointControls>
          <PreviewFrame theme={theme} width={width}>
            <ContentItem uid="root" />
          </PreviewFrame>
        </div>

        <ExportModeDisplay
          isShowing={viewMode === EDITOR_MODES.MARKUP}
          markup={formattedMarkup}
        />
      </div>
    </ThemeProvider>
  );
};

// TODO: connect this to an action for fetching the initial page markup
const mapStateToProps = (state) => {
  const formattedMarkup = getFormattedMarkup(state);
  return {
    isLoading: false,
    theme: getTheme(state),
    formattedMarkup,
    viewMode: getEditorMode(state),
    width: getPreviewWidth(state),
    activeBreakpoint: getActiveBreakpoint(state),
  };
};

export default connect(mapStateToProps, {
  setViewMode,
  setWidth: setPreviewWidth,
  clearCurrentEdit,
})(PageContent);
