import {
  SET_VIEW_MODE,
  CLEAR_CURRENT_EDIT,
  SET_CURRENT_EDIT,
} from "../actionTypes";
import { EDITOR_MODES } from "../constants";

export default function (state = null, action = {}) {
  switch (action.type) {
    case CLEAR_CURRENT_EDIT: {
      return null;
    }
    case SET_VIEW_MODE: {
      const { mode } = action.payload;
      if (mode === EDITOR_MODES.EDITING && state === null) {
        return "root";
      }

      return state;
    }
    case SET_CURRENT_EDIT: {
      return action.payload.uid;
    }
    default:
      return state;
  }
}
