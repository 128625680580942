import React from "react";
import styled from "styled-components";
import MarkupDisplay from "./MarkupDisplay";
import { Heading, Box, P } from "@mknudsen01/superblock";

const ExportModeDisplayContainer = styled(Box)`
  position: absolute;
  transform: ${(props) =>
    props.isShowing ? "translate(-50%, -100%)" : "translate(-50%, 0)"};
  transition: 0.3s transform ease;
  left: 50%;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: white;
`;

const ExportText = (props) => <P {...props} fontSize={[3]} py={[5]} />;

const ExportModeDisplay = ({ isShowing, markup }) => {
  return (
    <ExportModeDisplayContainer isShowing={isShowing} py={[12]} px={[12]}>
      <Box>
        <Heading fontSize={[4]}>Exporting to your own website</Heading>
        <ExportText>
          First, download <code>superblocks</code> and its peer dependencies:
        </ExportText>
        <MarkupDisplay language="js">
          yarn add @mknudsen01/superblock react react-dom styled-components
          styled-system react-intersection-observer react-spring
        </MarkupDisplay>

        <ExportText>Next, copy your page markup into your app: </ExportText>
        <MarkupDisplay>{markup}</MarkupDisplay>
      </Box>
    </ExportModeDisplayContainer>
  );
};

export default ExportModeDisplay;
