import set from "lodash.set";
import pageMarkup from "../../testMarkup.json";
import {
  ADD_ITEM,
  ADD_HOVER_PROP,
  DELETE_HOVER_PROP,
  DELETE_ITEM,
  UPDATE_TEXT,
  UPDATE_ITEM_PROP,
  RESET_ITEM_PROP,
  DELETE_ITEM_PROP,
  REORDER_SIBLINGS,
  MOVE_ITEM,
  MOVE_ITEM_TO_CONTAINER,
  DELETE_ANIMATION,
  ADD_ANIMATION,
} from "../actionTypes";
import { PROPS } from "../constants";
import { getRelativesForUid } from "../../lib/utils";

export default function (state = pageMarkup, action = {}) {
  switch (action.type) {
    case REORDER_SIBLINGS: {
      const { uid, sourceIndex, destinationIndex, parentId } = action.payload;

      const siblings = state[parentId].children;
      const updatedSiblings = Array.from(siblings);
      updatedSiblings.splice(sourceIndex, 1);
      updatedSiblings.splice(destinationIndex, 0, uid);
      const updatedParent = { ...state[parentId], children: updatedSiblings };

      return {
        ...state,
        [parentId]: updatedParent,
      };
    }
    case DELETE_ANIMATION: {
      const { parentId } = action.payload;

      return {
        ...state,
        [parentId]: {
          ...state[parentId],
          animation: null,
        },
      };
    }

    case ADD_ANIMATION: {
      const { parentId, animation } = action.payload;

      return {
        ...state,
        [animation.uid]: animation,
        [parentId]: {
          ...state[parentId],
          animation: animation.uid,
        },
      };
    }
    case DELETE_ITEM: {
      const { uid, parentId, idsToDelete } = action.payload;

      const siblingsWithItemDeleted = state[parentId].children.filter(
        (childId) => childId !== uid
      );

      const newState = {
        ...state,
        [parentId]: {
          ...state[parentId],
          children: siblingsWithItemDeleted,
        },
      };

      // delete the uid and its descendants from the state tree
      idsToDelete.forEach((idToDelete) => {
        delete newState[idToDelete];
      });

      return newState;
    }
    case ADD_ITEM: {
      const { parentId, newItem } = action.payload;

      const { uid: itemId } = newItem;

      return {
        ...state,
        [parentId]: {
          ...state[parentId],
          children: [...state[parentId].children, itemId],
        },
        [itemId]: newItem,
      };
    }
    case UPDATE_TEXT: {
      const { uid, text } = action.payload;
      const content = state[uid];

      return {
        ...state,
        [uid]: {
          ...content,
          children: text,
        },
      };
    }
    case UPDATE_ITEM_PROP:
    case RESET_ITEM_PROP: {
      const {
        uid,
        propName,
        value,
        breakpoint,
        currentValues,
      } = action.payload;

      let newValues = [...currentValues];

      // assign the new value
      newValues[breakpoint] = value;

      // run through the array to make sure there aren't empty values
      for (let i = 0; i < newValues.length; i++) {
        const value = newValues[i];
        if (typeof value === "undefined") {
          newValues[i] = null;
        }
      }
      const newProps = set(state[uid].props, propName, newValues);

      return {
        ...state,
        [uid]: {
          ...state[uid],
          props: newProps,
        },
      };
    }
    case ADD_HOVER_PROP: {
      const { uid } = action.payload;

      return {
        ...state,
        [uid]: {
          ...state[uid],
          props: {
            ...state[uid].props,
            [PROPS.HOVER]: {},
          },
        },
      };
    }
    case DELETE_ITEM_PROP: {
      const { uid, propName, breakpoint, currentValues } = action.payload;

      const newValues = [...currentValues];
      newValues[breakpoint] = null;

      return {
        ...state,
        [uid]: {
          ...state[uid],
          props: {
            ...state[uid].props,
            [propName]: newValues,
          },
        },
      };
    }
    case DELETE_HOVER_PROP: {
      const { uid } = action.payload;

      const newProps = { ...state[uid].props };

      delete newProps[PROPS.HOVER];

      return {
        ...state,
        [uid]: {
          ...state[uid],
          props: newProps,
        },
      };
    }

    case MOVE_ITEM_TO_CONTAINER: {
      const { targetId, sourceId } = action.payload;

      // find sourceId parent
      const { parent: sourceParentUid } = getRelativesForUid({
        tree: state,
        uid: sourceId,
      });

      // remove sourceId from parent children
      const sourceChildren = state[sourceParentUid].children.filter(
        (childId) => childId !== sourceId
      );

      return {
        ...state,
        [sourceParentUid]: {
          ...state[sourceParentUid],
          children: sourceChildren,
        },
        [targetId]: {
          ...state[targetId],
          children: [...state[targetId].children, sourceId],
        },
      };
    }

    case MOVE_ITEM: {
      const { targetId, sourceId, isBelow } = action.payload;

      // find sourceId parent
      const {
        parent: sourceParentUid,
        indexOfParent: sourceIndex, // only matters if moving within same parent
      } = getRelativesForUid({
        tree: state,
        uid: sourceId,
      });

      // remove sourceId from parent children
      const sourceChildren = state[sourceParentUid].children.filter(
        (childId) => childId !== sourceId
      );

      // find target parent and target's index of parent children
      const {
        parent: targetParentId,
        indexOfParent: destinationIndex,
      } = getRelativesForUid({ tree: state, uid: targetId });

      // use the now-filtered children if moving inside same parent
      const targetParentChildren =
        targetParentId === sourceParentUid
          ? sourceChildren
          : [...state[targetParentId].children];

      // if we just removed the source and it was before the destination, we need to reduce the destination index by 1
      const siblingAdjustment =
        targetParentId === sourceParentUid && sourceIndex < destinationIndex
          ? -1
          : 0;
      const indexOffset = isBelow ? 1 : 0;

      const updatedTargetParentChildren = [
        ...targetParentChildren.slice(
          0,
          destinationIndex + siblingAdjustment + indexOffset
        ),
        sourceId,
        ...targetParentChildren.slice(
          destinationIndex + siblingAdjustment + indexOffset
        ),
      ];

      if (targetParentId === sourceParentUid) {
        return {
          ...state,
          [targetParentId]: {
            ...state[targetParentId],
            children: updatedTargetParentChildren,
          },
        };
      }

      return {
        ...state,
        [sourceParentUid]: {
          ...state[sourceParentUid],
          children: sourceChildren,
        },
        [targetParentId]: {
          ...state[targetParentId],
          children: updatedTargetParentChildren,
        },
      };
    }

    default:
      return state;
  }
}
