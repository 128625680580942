import styled from "styled-components";

const Slider = styled.input`
  display: block;
  width: 100%;
  height: 4px;
  cursor: pointer;
  -webkit-appearance: none;
  color: black;
  background-color: #999;
  margin: 4px 0px;
  border-radius: 9999px;
  transition: 0.3s all ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 255, 0.3);
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    background: ${(props) => (props.hasValue ? "fuchsia" : "#999")};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    background: ${(props) => (props.hasValue ? "fuchsia" : "#999")};
    cursor: pointer;
  }
`;

export default Slider;
