import { combineReducers } from "redux";
import siteContent from "./content";
import theme from "./theme";
import editorMode from "./editorMode";
import currentEdit from "./currentEdit";
import previewWidth from "./previewWidth";
import focusedProp from "./focusedProp";

export default combineReducers({
  content: siteContent,
  theme,
  editorMode,
  currentEdit,
  previewWidth,
  focusedProp,
});
