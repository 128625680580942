export const EDITOR_MODES = {
  EDITING: "EDITING",
  PREVIEW: "PREVIEW",
  MARKUP: "MARKUP",
};

export const NOT_DEFINED = "NOT_DEFINED";

export const UPDATER_INPUT_STATES = {
  UNDEFINED_AND_NO_DEFAULT: "UNDEFINED_AND_NO_DEFAULT",
  DEFINED_BUT_NO_DEFAULT: "DEFINED_BUT_NO_DEFAULT",
  USES_DEFAULT: "USES_DEFAULT",
  MATCHES_DEFAULT: "MATCHES_DEFAULT",
  DIFFERS_FROM_DEFAULT: "DIFFERS_FROM_DEFAULT",
};

export const UPDATER_TYPES = {
  TEXT: "TEXT",
  COLOR: "COLOR",
  SELECT: "SELECT",
  DIRECTIONAL: "DIRECTIONAL",
  SLIDER: "SLIDER",
  BOX_SHADOW: "BOX_SHADOW",
  TEXT_WITH_UNIT: "TEXT_WITH_UNIT",
};

export const MOVE_DIRECTIONS = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
};

export const CONTENT_TYPES = {
  P: "P",
  ROOT: "Root",
  BOX: "Box",
  FLEX: "Flex",
  HEADING: "Heading",
  IMAGE: "Image",
  IN_VIEW_ANIMATION: "InViewAnimation",
};

export const DEFAULT_CHILDREN_BY_CONTENT_TYPE = {
  [CONTENT_TYPES.P]: "this is a new paragraph",
  [CONTENT_TYPES.BOX]: [],
  [CONTENT_TYPES.FLEX]: [],
  [CONTENT_TYPES.IMAGE]: [],
  [CONTENT_TYPES.HEADING]: "this is a new heading",
  [CONTENT_TYPES.ROOT]: [],
};

// this is for parsing/building up the page markup
export const JSX_TYPE_BY_CONTENT_TYPE = {
  [CONTENT_TYPES.P]: "P",
  [CONTENT_TYPES.BOX]: "Box",
  [CONTENT_TYPES.ROOT]: "Box",
  [CONTENT_TYPES.FLEX]: "Flex",
  [CONTENT_TYPES.HEADING]: "Heading",
  [CONTENT_TYPES.IMAGE]: "Image",
  [CONTENT_TYPES.IN_VIEW_ANIMATION]: "InViewAnimation",
};

export const TYPE_TO_DISPLAY_NAME = {
  [CONTENT_TYPES.P]: "Paragraph",
  [CONTENT_TYPES.BOX]: "Box",
  [CONTENT_TYPES.ROOT]: "Page root",
  [CONTENT_TYPES.FLEX]: "Layout Box",
  [CONTENT_TYPES.HEADING]: "Heading",
  [CONTENT_TYPES.IMAGE]: "Image",
};

export const PROPS = {
  CHILDREN: "children",
  FONT_SIZE: "fontSize",
  FONT_WEIGHT: "fontWeight",
  LINE_HEIGHT: "lineHeight",
  LETTER_SPACING: "letterSpacing",
  FONT_FAMILY: "fontFamily",
  PADDING: "p",
  PADDING_X: "px",
  PADDING_Y: "py",
  PADDING_T: "pt",
  PADDING_R: "pr",
  PADDING_B: "pb",
  PADDING_L: "pl",
  COLOR: "color",
  BACKGROUND_COLOR: "backgroundColor",
  TEXT_ALIGN: "textAlign",
  OPACITY: "opacity",
  ALIGN_ITEMS: "alignItems",
  JUSTIFY_CONTENT: "justifyContent",
  FLEX_DIRECTION: "flexDirection",
  FLEX_WRAP: "flexWrap",
  FONT_STYLE: "fontStyle",
  Y: "y",
  DELAY: "delay",
  THRESHOLD: "threshold",
  BOX_SHADOW: "boxShadow",
  WIDTH: "width",
  HEIGHT: "height",
  ALT: "alt",
  SRC: "src",
  SMALL_SRC: "smallSrc",
  ASPECT_RATIO: "aspectRatio",
  IMAGE_POSITION_Y: "imagePositionY",
  TRANSITION_DURATION: "transitionDuration",
  HOVER: "hover",
  HOVER_BACKGROUND_COLOR: "hover.backgroundColor",
  HOVER_COLOR: "hover.color",
  HOVER_BOX_SHADOW: "hover.boxShadow",
  HOVER_TRANSLATE_X: "hover.translateX",
  HOVER_TRANSLATE_Y: "hover.translateY",
  HOVER_SCALE: "hover.scale",
  HOVER_ROTATE: "hover.rotate",
  HOVER_TRANSITION_DURATION: "hover.transitionDuration",
  HOVER_TRANSITION_TIMING_FUNCTION: "hover.transitionTimingFunction",
  // TODO: implement each of these:
  MIN_WIDTH: "minWidth",
  MAX_WIDTH: "maxWidth",
  MIN_HEIGHT: "minHeight",
  MAX_HEIGHT: "maxHeight",
  DISPLAY: "display", // do i want this one?
  VERTICAL_ALIGN: "verticalAlign",
  OVERFLOW_X: "overflowX",
  OVERFLOW_Y: "overflowY",
  HEADING_LEVEL: "headingLevel",
  // flexbox:
  ALIGN_CONTENT: "alignContent",
  FLEX_GROW: "flexGrow",
  FLEX_SHRINK: "flexShrink",
  FLEX_BASIS: "flexBasis",
  JUSTIFY_SELF: "justifySelf",
  ALIGN_SELF: "alignSelf",
  ORDER: "order",
  TEXT_SHADOW: "textShadow",
  BACKGROUND_IMAGE: "backgroundImage",
  BACKGROUND_SIZE: "backgroundSize",
  BACKGROUND_POSITION: "backgroundPosition",
  BACKGROUND_REPEAT: "backgroundRepeat",
};

// TODO: Add props lists for other groupings
export const TYPOGRAPHY_PROPS = [
  PROPS.FONT_SIZE,
  PROPS.FONT_WEIGHT,
  PROPS.LINE_HEIGHT,
  PROPS.LETTER_SPACING,
  PROPS.FONT_FAMILY,
  PROPS.TEXT_ALIGN,
  PROPS.FONT_STYLE,
];

export const LAYOUT_PROPS = [PROPS.WIDTH, PROPS.HEIGHT];
export const COLOR_PROPS = [PROPS.COLOR, PROPS.BACKGROUND_COLOR, PROPS.OPACITY];
export const SHADOW_PROPS = [PROPS.BOX_SHADOW];
export const SPACE_PROPS = [PROPS.PADDING];
export const FLEX_PROPS = [
  PROPS.ALIGN_ITEMS,
  PROPS.JUSTIFY_CONTENT,
  PROPS.FLEX_WRAP,
  PROPS.FLEX_DIRECTION,
];
export const IMAGE_PROPS = [
  PROPS.SRC,
  PROPS.SMALL_SRC,
  PROPS.ASPECT_RATIO,
  PROPS.IMAGE_POSITION_Y,
];

export const HOVER_PROPS = [
  PROPS.HOVER_BACKGROUND_COLOR,
  PROPS.HOVER_COLOR,
  PROPS.HOVER_BOX_SHADOW,
  PROPS.HOVER_SCALE,
  PROPS.HOVER_ROTATE,
  PROPS.HOVER_TRANSLATE_X,
  PROPS.HOVER_TRANSLATE_Y,
  PROPS.HOVER_TRANSITION_DURATION,
  // HOVER_TRANSITION_TIMING_FUNCTION
];

// TODO: Add for other content types
export const PROPS_BY_CONTENT_TYPE = {
  [CONTENT_TYPES.P]: [
    ...TYPOGRAPHY_PROPS,
    ...COLOR_PROPS,
    ...SPACE_PROPS,
    ...SHADOW_PROPS,
    ...LAYOUT_PROPS,
  ],
  [CONTENT_TYPES.HEADING]: [
    PROPS.HEADING_LEVEL,
    ...TYPOGRAPHY_PROPS,
    ...COLOR_PROPS,
    ...SPACE_PROPS,
    ...SHADOW_PROPS,
    ...LAYOUT_PROPS,
  ],
  [CONTENT_TYPES.FLEX]: [
    PROPS.BACKGROUND_COLOR,
    PROPS.OPACITY,
    ...FLEX_PROPS,
    ...SPACE_PROPS,
    ...SHADOW_PROPS,
    ...LAYOUT_PROPS,
  ],
  [CONTENT_TYPES.BOX]: [
    PROPS.BACKGROUND_COLOR,
    PROPS.OPACITY,
    ...FLEX_PROPS,
    ...SPACE_PROPS,
    ...SHADOW_PROPS,
    ...LAYOUT_PROPS,
  ],
  [CONTENT_TYPES.IMAGE]: [...IMAGE_PROPS, ...SHADOW_PROPS],
  [CONTENT_TYPES.ROOT]: [
    PROPS.BACKGROUND_COLOR,
    PROPS.OPACITY,
    ...FLEX_PROPS,
    ...SPACE_PROPS,
  ],
};

export const PROPS_TO_THEME_KEY = {
  [PROPS.FONT_SIZE]: "fontSizes",
  [PROPS.FONT_WEIGHT]: "fontWeights",
  [PROPS.LINE_HEIGHT]: "lineHeights",
  [PROPS.LETTER_SPACING]: "letterSpacings",
  [PROPS.FONT_FAMILY]: "fonts",
  [PROPS.PADDING]: "space",
  [PROPS.PADDING_X]: "space",
  [PROPS.PADDING_Y]: "space",
  [PROPS.PADDING_L]: "space",
  [PROPS.PADDING_R]: "space",
  [PROPS.PADDING_T]: "space",
  [PROPS.PADDING_B]: "space",
  [PROPS.COLOR]: "colors",
  [PROPS.BACKGROUND_COLOR]: "colors",
  [PROPS.BOX_SHADOW]: "shadows",
  [PROPS.HOVER_COLOR]: "colors",
  [PROPS.HOVER_BACKGROUND_COLOR]: "colors",
  [PROPS.HOVER_BOX_SHADOW]: "shadows",
};

export const PROPS_TO_DISPLAY_NAME = {
  [PROPS.CHILDREN]: "Text content",
  [PROPS.FONT_SIZE]: "Text size",
  [PROPS.FONT_WEIGHT]: "Text boldness",
  [PROPS.LINE_HEIGHT]: "Line height",
  [PROPS.LETTER_SPACING]: "Letter spacing",
  [PROPS.FONT_FAMILY]: "Font family",
  [PROPS.PADDING]: "Padding",
  [PROPS.PADDING_X]: "Horizontal Padding",
  [PROPS.PADDING_Y]: "Vertical Padding",
  [PROPS.PADDING_L]: "Left Padding",
  [PROPS.PADDING_R]: "Right Padding",
  [PROPS.PADDING_T]: "Top Padding",
  [PROPS.PADDING_B]: "Bottom Padding",
  [PROPS.COLOR]: "Color",
  [PROPS.BACKGROUND_COLOR]: "Background Color",
  [PROPS.TEXT_ALIGN]: "Text alignment",
  [PROPS.OPACITY]: "Opacity",
  [PROPS.ALIGN_ITEMS]: "Alignment with siblings",
  [PROPS.JUSTIFY_CONTENT]: "Alignment inside of parent",
  [PROPS.FLEX_DIRECTION]: "Direction (row vs column)",
  [PROPS.FLEX_WRAP]: "Content wrapping",
  [PROPS.HEADING_LEVEL]: "Importance (1 is most important)",
  [PROPS.FONT_STYLE]: "Text styling",
  [PROPS.Y]: "Vertical jump amount",
  [PROPS.DELAY]: "Animation delay",
  [PROPS.THRESHOLD]: "On-screen threshold",
  [PROPS.BOX_SHADOW]: "Shadow",
  [PROPS.WIDTH]: "Width",
  [PROPS.HEIGHT]: "Height",
  [PROPS.HOVER]: "Hover",
  [PROPS.HOVER_COLOR]: "Color",
  [PROPS.HOVER_BACKGROUND_COLOR]: "Background color",
  [PROPS.HOVER_BOX_SHADOW]: "Shadow",
  [PROPS.HOVER_TRANSLATE_X]: "X-axis movement",
  [PROPS.HOVER_TRANSLATE_Y]: "Y-axis movement",
  [PROPS.HOVER_SCALE]: "Scale adjustment",
  [PROPS.HOVER_ROTATE]: "Rotation",
  [PROPS.HOVER_TRANSITION_DURATION]: "Transition Duration",
  [PROPS.HOVER_TRANSITION_TIMING_FUNCTION]: "Transition Timing",
};

export const PROPS_TO_DISPLAY_UNIT = {
  [PROPS.FONT_SIZE]: "px",
  [PROPS.FONT_WEIGHT]: "",
  [PROPS.LINE_HEIGHT]: "",
  [PROPS.LETTER_SPACING]: "px",
  [PROPS.FONT_FAMILY]: "",
  [PROPS.PADDING]: "px",
  [PROPS.PADDING_X]: "px",
  [PROPS.PADDING_Y]: "px",
  [PROPS.PADDING_T]: "px",
  [PROPS.PADDING_B]: "px",
  [PROPS.PADDING_L]: "px",
  [PROPS.PADDING_R]: "px",
  [PROPS.COLOR]: "",
  [PROPS.OPACITY]: "",
  [PROPS.HEADING_LEVEL]: "",
  [PROPS.FONT_STYLE]: "",
  [PROPS.Y]: "px",
  [PROPS.THRESHOLD]: "",
  [PROPS.DELAY]: "ms",
  [PROPS.BOX_SHADOW]: "",
  [PROPS.WIDTH]: "",
  [PROPS.HEIGHT]: "",
  [PROPS.HOVER_TRANSLATE_X]: "px",
  [PROPS.HOVER_TRANSLATE_Y]: "px",
  [PROPS.HOVER_SCALE]: "x",
  [PROPS.HOVER_ROTATE]: "deg",
  [PROPS.HOVER_TRANSITION_DURATION]: "s",
  [PROPS.HOVER_BOX_SHADOW]: "",
};

// TODO: Make this more extensible when custom breakpoints are allowed
export const BREAKPOINT_NAMES = ["small", "medium", "large"];
export const MIN_WIDTH = 320;
export const WIDTH_STEP = 10;

export const OPTIONS_BY_PROP = {
  [PROPS.TEXT_ALIGN]: ["left", "center", "right"],
  [PROPS.OPACITY]: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],

  // prettier-ignore
  [PROPS.ALIGN_ITEMS]: ["stretch","center","flex-start", "flex-end", "baseline"],
  // prettier-ignore
  [PROPS.JUSTIFY_CONTENT]: ["center","flex-start","flex-end","space-between", "space-around"],
  // prettier-ignore
  [PROPS.FLEX_DIRECTION]: ["row","row-reverse","column","column-reverse"],
  // prettier-ignore
  [PROPS.FLEX_WRAP]: ["nowrap", "wrap", "wrap-reverse"],
  [PROPS.HEADING_LEVEL]: [6, 5, 4, 3, 2, 1],
  [PROPS.FONT_STYLE]: ["normal", "italic"],
  [PROPS.THRESHOLD]: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  [PROPS.Y]: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  [PROPS.DELAY]: [0, 200, 400, 600, 800, 1000, 1200, 1400, 1600, 1800, 2000],
  [PROPS.WIDTH]: [
    { display: "%", value: "%" },
    { display: "px", value: "px" },
    { display: "em", value: "em" },
    { display: "rem", value: "rem" },
    { display: "vh", value: "vh" },
    { display: "vw", value: "vw" },
  ],
  [PROPS.HEIGHT]: [
    { display: "%", value: "%" },
    { display: "px", value: "px" },
    { display: "em", value: "em" },
    { display: "rem", value: "rem" },
    { display: "vh", value: "vh" },
    { display: "vw", value: "vw" },
  ],
  [PROPS.HOVER_ROTATE]: [
    { display: "deg", value: "deg" },
    { display: "rad", value: "rad" },
  ],
  [PROPS.HOVER_SCALE]: [{ display: "x", value: "" }],
  [PROPS.HOVER_TRANSLATE_X]: [{ display: "px", value: "" }],
  [PROPS.HOVER_TRANSLATE_Y]: [{ display: "px", value: "" }],
  [PROPS.HOVER_TRANSITION_DURATION]: [{ display: "s", value: "" }],
};

export const INPUT_TYPES_BY_PROP = {
  [PROPS.CHILDREN]: UPDATER_TYPES.TEXT,
  [PROPS.HEADING_LEVEL]: UPDATER_TYPES.SLIDER,
  [PROPS.Y]: UPDATER_TYPES.SLIDER,
  [PROPS.DELAY]: UPDATER_TYPES.SLIDER,
  [PROPS.THRESHOLD]: UPDATER_TYPES.SLIDER,
  [PROPS.FONT_SIZE]: UPDATER_TYPES.SLIDER,
  [PROPS.FONT_WEIGHT]: UPDATER_TYPES.SLIDER,
  [PROPS.LINE_HEIGHT]: UPDATER_TYPES.SLIDER,
  [PROPS.LETTER_SPACING]: UPDATER_TYPES.SLIDER,
  [PROPS.FONT_FAMILY]: UPDATER_TYPES.SELECT,
  [PROPS.TEXT_ALIGN]: UPDATER_TYPES.SELECT,
  [PROPS.FONT_STYLE]: UPDATER_TYPES.SELECT,
  [PROPS.COLOR]: UPDATER_TYPES.COLOR,
  [PROPS.BACKGROUND_COLOR]: UPDATER_TYPES.COLOR,
  [PROPS.OPACITY]: UPDATER_TYPES.SLIDER,
  [PROPS.ALIGN_ITEMS]: UPDATER_TYPES.SELECT,
  [PROPS.JUSTIFY_CONTENT]: UPDATER_TYPES.SELECT,
  [PROPS.FLEX_DIRECTION]: UPDATER_TYPES.SELECT,
  [PROPS.FLEX_WRAP]: UPDATER_TYPES.SELECT,
  [PROPS.PADDING]: UPDATER_TYPES.DIRECTIONAL,
  [PROPS.BOX_SHADOW]: UPDATER_TYPES.BOX_SHADOW,
  [PROPS.WIDTH]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.HEIGHT]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.SRC]: UPDATER_TYPES.TEXT,
  [PROPS.SMALL_SRC]: UPDATER_TYPES.TEXT,
  [PROPS.ASPECT_RATIO]: UPDATER_TYPES.TEXT,
  [PROPS.IMAGE_POSITION_Y]: UPDATER_TYPES.TEXT,
  [PROPS.HOVER_BACKGROUND_COLOR]: UPDATER_TYPES.COLOR,
  [PROPS.HOVER_COLOR]: UPDATER_TYPES.COLOR,
  [PROPS.HOVER_BOX_SHADOW]: UPDATER_TYPES.BOX_SHADOW,
  [PROPS.HOVER_TRANSLATE_X]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.HOVER_TRANSLATE_Y]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.HOVER_SCALE]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.HOVER_ROTATE]: UPDATER_TYPES.TEXT_WITH_UNIT,
  [PROPS.HOVER_TRANSITION_DURATION]: UPDATER_TYPES.TEXT_WITH_UNIT,
};

export const PLACEHOLDERS_BY_PROP = {
  [PROPS.WIDTH]: 100,
  [PROPS.HEIGHT]: 100,
};
