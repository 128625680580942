import React from "react";
import { connect } from "react-redux";
import { updateText, updateItemProp } from "../redux/actions";
import {
  getUid,
  getItemContent,
  getDefinedPropValuesForItem,
  getActiveBreakpoint,
} from "../redux/selectors";
import { PROPS_TO_DISPLAY_NAME, NOT_DEFINED } from "../redux/constants";
import { getValueForBreakpoint, isDefined } from "../lib/utils";
import Input from "./styles/Input";
import Label from "./styles/Label";

const ContentUpdaterText = ({
  uid,
  currentValue,
  updateText,
  updateItemProp,
  name,
  breakpoint,
}) => {
  const hasValue = isDefined(currentValue);
  const handleChange = (e) => {
    if (!name || name === "children") {
      return updateText({ uid, text: e.target.value });
    }

    const isNumber =
      `${parseInt(e.target.value, 10)}`.length === e.target.value.length;
    updateItemProp({
      value: isNumber ? parseInt(e.target.value, 10) : e.target.value,
      propName: name,
      breakpoint,
      uid,
    });
  };
  return (
    <>
      <Label htmlFor={`${name}-${breakpoint}`}>
        {PROPS_TO_DISPLAY_NAME[name] || name}
      </Label>
      <Input
        id={`${name}-${breakpoint}`}
        type="text"
        value={hasValue ? currentValue : ""}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            document.activeElement.blur();
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const uid = getUid(state);
  const content = getItemContent(state, { uid });
  if (ownProps.name === "children" || !ownProps.name) {
    return {
      uid,
      currentValue: content.children,
    };
  }
  const activeBreakpoint = getActiveBreakpoint(state);

  const definedValues = getDefinedPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });

  const definedValue = isDefined(definedValues)
    ? getValueForBreakpoint(definedValues, activeBreakpoint)
    : null;

  const currentValue = isDefined(definedValue) ? definedValue : NOT_DEFINED;

  return {
    uid,
    breakpoint: getActiveBreakpoint(state),
    currentValue,
  };
};

export default connect(mapStateToProps, { updateText, updateItemProp })(
  ContentUpdaterText
);
