import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { EDITOR_MODES } from "../redux/constants";
import Eye from "./icons/Eye";
import Code from "./icons/Code";
import Edit from "./icons/Edit";

const StyledPageControls = styled.div`
  display: flex;
  position: fixed;
  top: 40px;
  right: 40px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  z-index: 10;
`;

const StyledControl = styled.button`
  display: flex;
  flex: 1 1 auto;
  padding: 8px;
  border: 1px solid;
  border-radius: 5px;
  border-color: ${(props) => (props.isActive ? "fuchsia" : "white")};
  svg {
    stroke: ${(props) => (props.isActive ? "fuchsia" : "currentColor")};
  }
`;

const PageControls = ({ setViewMode, viewMode }) => {
  return (
    <StyledPageControls>
      <Tooltip title="Edit site (E)">
        <StyledControl
          isActive={viewMode === EDITOR_MODES.EDITING}
          onClick={() => setViewMode(EDITOR_MODES.EDITING)}
        >
          <Edit />
        </StyledControl>
      </Tooltip>
      <Tooltip title="Preview site (P)">
        <StyledControl
          isActive={viewMode === EDITOR_MODES.PREVIEW}
          onClick={() => setViewMode(EDITOR_MODES.PREVIEW)}
        >
          <Eye />
        </StyledControl>
      </Tooltip>
      <Tooltip title="View code (C)">
        <StyledControl
          isActive={viewMode === EDITOR_MODES.MARKUP}
          onClick={() => setViewMode(EDITOR_MODES.MARKUP)}
        >
          <Code />
        </StyledControl>
      </Tooltip>
    </StyledPageControls>
  );
};

export default PageControls;
