import styled from "styled-components";

export const BreakpointControls = styled.div`
  width: 320px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledButtons = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 10px 0;
`;

export const StyledButton = styled.button`
  background: ${(props) => (props.isActive ? "fuchsia" : "hotpink")};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
`;

export const BreakpointControlDisplay = styled.div`
  padding: 5px 0;
`;
