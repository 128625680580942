import { v4 as uuid } from "uuid";
import {
  CONTENT_TYPES,
  DEFAULT_CHILDREN_BY_CONTENT_TYPE,
  PROPS,
} from "../redux/constants";

export const createItem = ({ type }) => {
  const uid = uuid();

  switch (type) {
    case CONTENT_TYPES.P:
      return createParagraph({ uid, type });
    case CONTENT_TYPES.BOX:
      return createBox({ uid, type });
    case CONTENT_TYPES.FLEX:
      return createFlex({ uid, type });
    case CONTENT_TYPES.HEADING:
      return createHeading({ uid, type });
    case CONTENT_TYPES.IN_VIEW_ANIMATION:
      return createInViewAnimation({ uid, type });
    default:
      return Error(`${type} is an unsupported item creation type`);
  }
};

const createParagraph = ({ uid, type }) => {
  return {
    uid,
    type,
    children: DEFAULT_CHILDREN_BY_CONTENT_TYPE[CONTENT_TYPES.P],
    props: {},
  };
};

const createHeading = ({ uid, type }) => {
  return {
    uid,
    type,
    children: DEFAULT_CHILDREN_BY_CONTENT_TYPE[CONTENT_TYPES.HEADING],
    props: {},
  };
};

const createBox = ({ uid, type }) => {
  return {
    uid,
    type,
    children: DEFAULT_CHILDREN_BY_CONTENT_TYPE[CONTENT_TYPES.BOX],
    props: {},
  };
};

const createFlex = ({ uid, type }) => {
  return {
    uid,
    type,
    children: DEFAULT_CHILDREN_BY_CONTENT_TYPE[CONTENT_TYPES.FLEX],
    props: {},
  };
};

// TODO - move these props to default props in the theme?
const createInViewAnimation = ({ uid, type }) => {
  return {
    uid,
    type,
    props: {
      [PROPS.Y]: [40],
      [PROPS.DELAY]: [0.4],
      [PROPS.THRESHOLD]: [0.2],
    },
  };
};
