const colors = {
  black: ["#000000"],
  white: ["#FFFFFF"],
  gray: [
    "#F7FAFC",
    "#EDF2F7",
    "#E2E8F0",
    "#CBD5E0",
    "#A0AEC0",
    "#718096",
    "#4A5568",
    "#2D3748",
    "#1A202C",
  ],
  red: [
    "#FFF5F5",
    "#FED7D7",
    "#FEB2B2",
    "#FC8181",
    "#F56565",
    "#E53E3E",
    "#C53030",
    "#9B2C2C",
    "#742A2A",
  ],
  orange: [
    "#FFFAF0",
    "#FEEBC8",
    "#FBD38D",
    "#F6AD55",
    "#ED8936",
    "#DD6B20",
    "#C05621",
    "#9C4221",
    "#7B341E",
  ],
  yellow: [
    "#FFFFF0",
    "#FEFCBF",
    "#FAF089",
    "#F6E05E",
    "#ECC94B",
    "#D69E2E",
    "#B7791F",
    "#975A16",
    "#744210",
  ],
  green: [
    "#F0FFF4",
    "#C6F6D5",
    "#9AE6B4",
    "#68D391",
    "#48BB78",
    "#38A169",
    "#2F855A",
    "#276749",
    "#22543D",
  ],
  teal: [
    "#E6FFFA",
    "#B2F5EA",
    "#81E6D9",
    "#4FD1C5",
    "#38B2AC",
    "#319795",
    "#2C7A7B",
    "#285E61",
    "#234E52",
  ],
  blue: [
    "#EBF8FF",
    "#BEE3F8",
    "#90CDF4",
    "#63B3ED",
    "#4299E1",
    "#3182CE",
    "#2B6CB0",
    "#2C5282",
    "#2A4365",
  ],
  indigo: [
    "#EBF4FF",
    "#C3DAFE",
    "#A3BFFA",
    "#7F9CF5",
    "#667EEA",
    "#5A67D8",
    "#4C51BF",
    "#434190",
    "#3C366B",
  ],
  purple: [
    "#FAF5FF",
    "#E9D8FD",
    "#D6BCFA",
    "#B794F4",
    "#9F7AEA",
    "#805AD5",
    "#6B46C1",
    "#553C9A",
    "#44337A",
  ],
  pink: [
    "#FFF5F7",
    "#FED7E2",
    "#FBB6CE",
    "#F687B3",
    "#ED64A6",
    "#D53F8C",
    "#B83280",
    "#97266D",
    "#702459",
  ],
};

export default {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  // TODO: implement these
  // prettier-ignore
  space: [0,1,4,8,12,16,20,24,32,40,48,64,80,96,128,160,192,224,256],
  colors,
  fonts: {
    sans: "-apple-system, sans-serif",
    serif: "serif",
    monospace: "Menlo, monospace",
  },
  fontWeights: [100, 200, 300, 400, 500, 600, 700, 800, 900],
  lineHeights: [1.0, 1.25, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0],
  letterSpacings: [-1, -0.5, 0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4],
  sizes: [],
  borders: [],
  borderWidths: [],
  borderStyles: {},
  radii: [],
  shadows: [
    "0",
    "0 0 0 1px rgba(0, 0, 0, 0.05)",
    "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
    "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
    "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
    "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
  ],
  zIndices: [],
  styles: {
    Root: {},
    P: {
      fontFamily: ["sans"],
      fontSize: [2, 3],
      fontWeight: [2],
      lineHeight: [2],
      letterSpacing: [2],
      p: [4],
      test: true,
    },
    Heading: {
      fontFamily: ["sans"],
      fontSize: [4, 5, 6],
      fontWeight: [3],
      lineHeight: [3],
      letterSpacing: [3],
      headingLevel: [2],
      p: [4],
    },
  },
};
