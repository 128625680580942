import React from "react";
import ContentUpdaterColor from "./ContentUpdaterColor";
import ContentUpdaterDirectional from "./ContentUpdaterDirectional";
import ContentUpdaterSelect from "./ContentUpdaterSelect";
import ContentUpdaterSlider from "./ContentUpdaterSlider";
import ContentUpdaterText from "./ContentUpdaterText";
import ContentUpdaterBoxShadow from "./ContentUpdaterBoxShadow";
import ContentUpdaterTextWithUnits from "./ContentUpdaterTextWithUnits";
import { UPDATER_TYPES, INPUT_TYPES_BY_PROP } from "../redux/constants";

const COMPONENTS_BY_INPUT_TYPE = {
  [UPDATER_TYPES.TEXT]: ContentUpdaterText,
  [UPDATER_TYPES.COLOR]: ContentUpdaterColor,
  [UPDATER_TYPES.SELECT]: ContentUpdaterSelect,
  [UPDATER_TYPES.DIRECTIONAL]: ContentUpdaterDirectional,
  [UPDATER_TYPES.SLIDER]: ContentUpdaterSlider,
  [UPDATER_TYPES.BOX_SHADOW]: ContentUpdaterBoxShadow,
  [UPDATER_TYPES.TEXT_WITH_UNIT]: ContentUpdaterTextWithUnits,
};

const PropUpdaterControl = ({ name, uid }) => {
  // uid is passed it it's _not_ for the currently edited uid
  const inputType = INPUT_TYPES_BY_PROP[name];
  const Component = COMPONENTS_BY_INPUT_TYPE[inputType];
  return <Component name={name} uid={uid} />;
};

export default PropUpdaterControl;
