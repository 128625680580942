import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { PROPS_TO_THEME_KEY, PROPS_TO_DISPLAY_NAME } from "../redux/constants";
import { updateItemProp, setFocusedProp } from "../redux/actions";
import { getValueForBreakpoint } from "../lib/utils";
import {
  getCurrentPropValuesForItem,
  getOptionsForThemeKey,
  getActiveBreakpoint,
  getFocusedProp,
} from "../redux/selectors";
import Select from "./styles/Select";
import Label from "./styles/Label";
import PropUpdaterInputActions from "./PropUpdaterInputActions";

const Display = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`;

const ShadesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding: 10px 0 0;
`;

const ColorDisplay = styled.div`
  width: 40px;
  height: 20px;
  background: ${(props) => props.color || "transparent"};
`;

const ColorRadio = styled.input`
  appearance: none;
  flex: 1;
  height: 20px;
  display: flex;
  border-color: ${(props) => props.color};
  background: ${(props) => props.color};

  &:checked {
    border: 2px solid fuchsia;
  }
`;

// TODO: instead of black.0, use default value of nothing selected
const ContentUpdaterColor = ({
  uid,
  name,
  options,
  currentValue,
  updateItemProp,
  breakpoint = 0,
  isFocused,
  setFocusedProp,
}) => {
  const hasValue = !!currentValue;
  const color = hasValue ? currentValue.split(".")[0] : "";
  const shade = hasValue ? currentValue.split(".")[1] : "";

  const handleChangeColor = (e) => {
    const newColor = e.target.value;
    let newShade = shade || 0;

    if (!newColor || !options[newColor]) {
      updateItemProp({
        value: "",
        propName: name,
        breakpoint,
      });
      return;
    }

    if (options[newColor].length < parseInt(shade, 10)) {
      newShade = options[newColor].length - 1;
    }
    updateItemProp({
      value: `${newColor}.${newShade}`,
      propName: name,
      breakpoint,
    });
  };

  const handleChangeShade = (e) => {
    const newShade = e.target.value;
    updateItemProp({
      value: `${color}.${newShade}`,
      propName: name,
      breakpoint,
    });
  };

  const colors = ["", ...Object.keys(options)];
  const shades = options[color] || [];

  return (
    <>
      <Display>
        <Label htmlFor={`${name}-${breakpoint}`}>
          {PROPS_TO_DISPLAY_NAME[name] || name}
        </Label>
        <ColorDisplay color={hasValue ? options[color][shade] : ""} />
      </Display>
      <Select
        id={`${name}-${breakpoint}`}
        value={color}
        onChange={handleChangeColor}
        onFocus={() => !isFocused && setFocusedProp(name)}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            document.activeElement.blur();
          }
        }}
      >
        {colors.map((opt) => {
          return (
            <option key={opt} value={opt}>
              {opt}
            </option>
          );
        })}
      </Select>
      <ShadesContainer>
        {shades.map((color, index) => {
          return (
            <ColorRadio
              key={index}
              onChange={handleChangeShade}
              onFocus={() => {
                !isFocused && setFocusedProp(name);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 27) {
                  document.activeElement.blur();
                }
              }}
              checked={index === parseInt(shade, 10)}
              type="radio"
              name={`${name}-shade`}
              color={color}
              value={index}
            />
          );
        })}
      </ShadesContainer>
      {/*
// TODO: this will display a grid of the colors, but would need to adapt
// to allow for keyboard control. radio works, but up and down don't jump
// up and down a row of color, as one might assume would work
      <>
        {colors.map((colorName) => (
          <ShadesContainer>
            {options[colorName].map((shadeColor, index) => {
              return (
                <ColorRadio
                  onChange={handleChangeThing}
                  checked={color === colorName && index === parseInt(shade, 10)}
                  type="radio"
                  name="shade"
                  color={shadeColor}
                  value={`${colorName}.${index}`}
                />
              );
            })}
          </ShadesContainer>
        ))}
      </> */}

      {/* TODO: add deleteDefault and clearPropValue options ala contentUpdateSlider */}
      <PropUpdaterInputActions name={name} uid={uid} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const themeKey = PROPS_TO_THEME_KEY[ownProps.name];

  const currentValues = getCurrentPropValuesForItem(state, {
    name: ownProps.name,
  });

  const currentValue = getValueForBreakpoint(
    currentValues,
    ownProps.breakpoint
  );

  const isFocused = getFocusedProp(state) === ownProps.name;

  return {
    options: getOptionsForThemeKey(state, { themeKey }),
    currentValue,
    breakpoint: getActiveBreakpoint(state),
    isFocused,
  };
};

export default connect(mapStateToProps, {
  updateItemProp,
  setFocusedProp,
})(ContentUpdaterColor);
