import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Slider from "./styles/Slider";
import Label from "./styles/Label";
import {
  PROPS_TO_THEME_KEY,
  PROPS_TO_DISPLAY_NAME,
  PROPS_TO_DISPLAY_UNIT,
  NOT_DEFINED,
  OPTIONS_BY_PROP,
} from "../redux/constants";
import {
  updateItemProp,
  resetItemProp,
  updateDefaultStyle,
  deleteItemProp,
  setFocusedProp,
} from "../redux/actions";
import { getValueForBreakpoint, isDefined } from "../lib/utils";
import {
  getOptionsForThemeKey,
  getDefaultPropValuesForItem,
  getDefinedPropValuesForItem,
  getActiveBreakpoint,
} from "../redux/selectors";
import PropUpdaterInputActions from "./PropUpdaterInputActions";

const Display = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

const InputContainer = styled.div`
  padding-bottom: 5px;
`;

// TODO: Split into dumb and smart components
const ContentUpdaterSlider = ({
  uid,
  name,
  options,
  currentValue,
  updateItemProp,
  hasThemeKey,
  breakpoint = 0,
  setFocusedProp,
}) => {
  const hasValue = isDefined(currentValue);

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value, 10);
    updateItemProp({
      value: hasThemeKey ? newValue : options[newValue],
      propName: name,
      breakpoint,
      uid,
    });
  };

  const value = hasThemeKey
    ? currentValue
    : options.findIndex((x) => x === currentValue);

  return (
    <InputContainer>
      <Display>
        <Label htmlFor={`${name}-${breakpoint}`}>
          {PROPS_TO_DISPLAY_NAME[name] || name}
        </Label>
        <div>
          {hasValue ? `${options[value]} ${PROPS_TO_DISPLAY_UNIT[name]}` : ""}
        </div>
      </Display>
      <Slider
        type="range"
        id={`${name}-${breakpoint}`}
        name={`${name}-${breakpoint}`}
        min={0}
        max={options.length - 1}
        value={value}
        onFocus={() => {
          setFocusedProp(name);
        }}
        onChange={handleChange}
        hasValue={hasValue}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            document.activeElement.blur();
          }
        }}
      />
      <PropUpdaterInputActions name={name} uid={uid} />
    </InputContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const themeKey = PROPS_TO_THEME_KEY[ownProps.name];
  const activeBreakpoint = getActiveBreakpoint(state);

  const definedValues = getDefinedPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });
  const definedValue = isDefined(definedValues)
    ? getValueForBreakpoint(definedValues, activeBreakpoint)
    : null;

  const defaultValues = getDefaultPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });
  const defaultValue = isDefined(defaultValues)
    ? getValueForBreakpoint(defaultValues, activeBreakpoint)
    : null;

  const currentValue = isDefined(definedValue)
    ? definedValue
    : isDefined(defaultValue)
    ? defaultValue
    : NOT_DEFINED;

  const options = themeKey
    ? getOptionsForThemeKey(state, { themeKey })
    : OPTIONS_BY_PROP[ownProps.name];

  return {
    options,
    hasThemeKey: !!themeKey,
    currentValue,
    breakpoint: activeBreakpoint,
  };
};

export default connect(mapStateToProps, {
  updateItemProp,
  resetItemProp,
  updateDefaultStyle,
  deleteItemProp,
  setFocusedProp,
})(ContentUpdaterSlider);
