import React from "react";
import { StyleSheetManager } from "styled-components";
import Frame, { FrameContextConsumer } from "react-frame-component";
import GlobalReset from "./GlobalStyleReset";
import { CurrentBreakpointProvider } from "@mknudsen01/superblock";

function PreviewFrame({ children, width }) {
  return (
    <Frame
      style={{
        height: window.innerHeight - 220,
        margin: "0 auto 30px",
        overflow: "auto",
        background: "white",
        boxShadow:
          "0 0px 8px 1px rgba(0, 0, 0, .1), 0 2px 4px -1px rgba(0, 0, 0, .06)",
        width,
      }}
    >
      <FrameContextConsumer>
        {(frameContext) => {
          return (
            <CurrentBreakpointProvider observedWindow={frameContext.window}>
              <StyleSheetManager target={frameContext.document.head}>
                <div style={{ overflowX: "hidden" }}>
                  <GlobalReset />
                  {children}
                </div>
              </StyleSheetManager>
            </CurrentBreakpointProvider>
          );
        }}
      </FrameContextConsumer>
    </Frame>
  );
}

export default PreviewFrame;
