import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  clearCurrentEdit,
  deleteItem,
  moveSiblingLeft,
  moveSiblingRight,
} from "../redux/actions";
import { TYPE_TO_DISPLAY_NAME } from "../redux/constants";

import { Flex } from "@mknudsen01/superblock";
import AddItemController from "../components/AddItemController";
import AnimationController from "../components/AnimationController";
import HoverController from "../components/HoverController";
import PropUpdatersContainer from "../components/PropUpdatersContainer";
import TreeView from "../components/TreeView";
import CloseIcon from "../components/icons/Close";
import {
  getUid,
  getItemContent,
  getTheme,
  getRelatives,
} from "../redux/selectors";

const StyledEditingPane = styled.div`
  width: 300px;
  position: fixed;
  transform: ${(props) =>
    props.uid && props.canEdit ? `translateX(0)` : `translateX(-100%)`};
  top: 0;
  bottom: 0;
  padding: 10px;
  background: white;
  height: 100vh;
  overflow-y: auto;
  transition: 0.4s all ease;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

const EditingPaneHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DeleteItem = styled.button`
  background: white;
  color: ${(props) => props.theme.colors["red"][5]};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors["red"][5]};
  border-radius: 5px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors["red"][5]};
    color: white;
  }
`;

const MoveItemButton = styled.button`
  background: white;
  color: ${(props) => props.theme.colors["blue"][5]};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors["blue"][5]};
  border-radius: 5px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors["blue"][5]};
    color: white;
  }
`;

const getDisplayName = (type) => {
  return TYPE_TO_DISPLAY_NAME[type] || "Element";
};

function EditingPane({
  uid,
  content,
  canEdit,
  clearCurrentEdit,
  relatives,
  deleteItem,
  moveSiblingLeft,
  moveSiblingRight,
  hasPreviousSibling,
  hasNextSibling,
}) {
  const { type } = content;

  return (
    <StyledEditingPane uid={uid} canEdit={canEdit}>
      {uid && (
        <>
          <EditingPaneHeader>
            <p style={{ fontWeight: "bold" }}>{getDisplayName(type)}</p>
            <button onClick={clearCurrentEdit}>
              <CloseIcon />
            </button>
          </EditingPaneHeader>

          <hr />

          {/* <EditingPaneTreeView /> */}

          <TreeView />

          <hr />

          <AddItemController />
          <hr />

          <AnimationController />

          <hr />

          {uid !== "root" && (
            <>
              <HoverController />
              <hr />
            </>
          )}

          {uid !== "root" && (
            <DeleteItem
              onClick={() => {
                deleteItem();
                clearCurrentEdit();
              }}
            >
              Delete Item
            </DeleteItem>
          )}
          <Flex flexDirection="row" py={5} justifyContent="space-between">
            {!!hasPreviousSibling && (
              <MoveItemButton onClick={moveSiblingLeft}>
                ← Move left
              </MoveItemButton>
            )}
            {!!hasNextSibling && (
              <MoveItemButton onClick={moveSiblingRight}>
                Move right →
              </MoveItemButton>
            )}
          </Flex>

          <PropUpdatersContainer />
        </>
      )}
    </StyledEditingPane>
  );
}

const mapStateToProps = (state) => {
  const uid = getUid(state);
  const content = getItemContent(state, { uid });
  const theme = getTheme(state);
  const breakpointsCount = theme.breakpoints.length;
  const relatives = getRelatives(state);
  const { previousSibling, nextSibling } = relatives;

  return {
    uid,
    content,
    breakpointsCount,
    hasPreviousSibling: !!previousSibling,
    hasNextSibling: !!nextSibling,
    relatives,
  };
};

export default connect(mapStateToProps, {
  clearCurrentEdit,
  deleteItem,
  moveSiblingLeft,
  moveSiblingRight,
})(EditingPane);
