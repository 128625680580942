import * as t from "@babel/types";
import generate from "@babel/generator";
import template from "@babel/template";
import prettier from "prettier/standalone";
import parserBabel from "prettier/parser-babel";
import unique from "lodash.uniq";
import { JSX_TYPE_BY_CONTENT_TYPE, CONTENT_TYPES } from "../redux/constants";

const findElementsInTree = (jsx) => {
  // console.log({ jsx, type: typeof jsx });
  const matches = jsx.matchAll(/<([a-zA-Z]+)\b/g);
  // const jsxElements = [...matches].map(match => match[1]);
  const jsxElements = [...matches].reduce((acc, match) => {
    if (acc[match[1]]) {
      return acc;
    }
    return {
      ...acc,
      [match[1]]: true,
    };
  }, {});
  return Object.keys(jsxElements);
};

const grabGaudiElements = (elements) => {
  return unique(Object.values(JSX_TYPE_BY_CONTENT_TYPE)).filter((pdElement) => {
    return elements.includes(pdElement);
  });
};

const SELF_CLOSING_TYPES = [CONTENT_TYPES.IMAGE];
const getIsSelfClosing = (tag) => SELF_CLOSING_TYPES.includes(tag);

const buildBasicTemplate = template(`
  import React from 'react';
  import {ThemeProvider} from 'styled-components';
  %%gaudiImports%%

  %%theme%%

  export default () => {
    return (
      %%jsx%%
    )
  }
`);

const buildAttributeValue = (value, shouldWrapInExpressionContainer = true) => {
  if (typeof value === "string") {
    return t.stringLiteral(value);
  }
  if (typeof value === "boolean") {
    if (!!value) {
      return null;
    } else {
      return t.jsxExpressionContainer(t.booleanLiteral(value));
    }
  }

  if (typeof value === "number") {
    if (shouldWrapInExpressionContainer) {
      return t.jsxExpressionContainer(t.numericLiteral(value));
    } else {
      return t.numericLiteral(value);
    }
  }

  if (value === null) {
    return t.nullLiteral();
  }

  if (Array.isArray(value)) {
    return t.jsxExpressionContainer(
      t.arrayExpression(value.map((v) => buildAttributeValue(v, false)))
    );
  }

  if (typeof value === "object" && !!value) {
    //object
    return t.jsxExpressionContainer(parseObject(value));
  }

  throw new TypeError(
    `${value} is in an unsupported format - ${typeof value} `
  );
};

const buildAttributes = (props) => {
  return Object.entries(props).map(([key, value]) => {
    return t.jsxAttribute(t.jsxIdentifier(key), buildAttributeValue(value));
  });
};

const buildOpeningElement = ({ name, props, selfClosing }) => {
  const elementName = t.jsxIdentifier(name);
  const attributes = buildAttributes(props);
  return t.jsxOpeningElement(elementName, attributes, selfClosing);
};

const parseArray = (array) => {
  const elements = array.map((elem) => {
    if (typeof elem === "string") {
      return t.stringLiteral(elem);
    } else if (typeof elem === "number") {
      return t.numericLiteral(elem);
    } else if (typeof elem === "object" && !!elem) {
      //object
      return parseObject(elem);
    } else {
      throw new Error("unexpected type in parseArray: ", elem);
    }
  });
  return t.arrayExpression(elements);
};

const parseObject = (object) => {
  const properties = Object.keys(object).map((key) => {
    let value;
    const val = object[key];
    if (Array.isArray(val)) {
      value = parseArray(val);
    } else if (typeof val === "object" && !!val) {
      value = parseObject(val);
    } else if (typeof val === "string") {
      value = t.stringLiteral(val);
    } else if (typeof val === "number") {
      value = t.numericLiteral(val);
    } else if (typeof val === "boolean") {
      value = t.booleanLiteral(val);
    } else {
      throw new Error(
        "unexpected element. type: ",
        typeof val,
        " value: ",
        val
      );
    }
    return t.objectProperty(t.identifier(key), value);
  });
  return t.objectExpression(properties);
};

export const parse = ({
  input = {},
  target = "root",
  theme,
  parsedAnimations = [],
  parentOfAnimation,
}) => {
  const currentItem = input[target];
  const { children: itemChildren, props, type, animation } = currentItem;
  const defaultStyles = (theme && theme.styles && theme.styles[type]) || {};

  const isTextElement = !!itemChildren && !Array.isArray(itemChildren);
  const isSelfClosing = getIsSelfClosing(type);

  // if an animation exists, parse that first
  if (!!animation && !parsedAnimations.includes(animation)) {
    return parse({
      input,
      target: animation,
      theme,
      parsedAnimations: [...parsedAnimations, animation],
      parentOfAnimation: target,
    });
  }

  const jsxType = JSX_TYPE_BY_CONTENT_TYPE[type] || type;

  let parsedChildren;
  if (isTextElement) {
    parsedChildren = [t.jsxText(itemChildren)];
  } else if (isSelfClosing) {
    parsedChildren = [];
  } else if (!!parentOfAnimation) {
    // parse the animation, with the children as the original parent
    parsedChildren = [
      parse({
        input,
        target: parentOfAnimation,
        theme,
        parsedAnimations,
        isAnimation: false,
      }),
    ];
  } else {
    parsedChildren = itemChildren.map((childId) => {
      return parse({ input, target: childId, theme, parsedAnimations });
    });
  }

  const openingElement = buildOpeningElement({
    name: jsxType,
    props: { ...defaultStyles, ...props },
    selfClosing: isSelfClosing,
  });
  const closingElement = isSelfClosing
    ? null
    : t.jsxClosingElement(t.jsxIdentifier(jsxType));

  return t.jsxElement(openingElement, closingElement, parsedChildren);
};

const buildPageJsxWithBreakpointProvider = (ast) =>
  t.jsxElement(
    t.jsxOpeningElement(
      t.jsxIdentifier("CurrentBreakpointProvider"),
      [
        t.jsxAttribute(
          t.jsxIdentifier("breakpoints"),
          t.jsxExpressionContainer(t.identifier("theme.breakpoints"))
        ),
      ],
      false
    ),
    t.jsxClosingElement(t.jsxIdentifier("CurrentBreakpointProvider")),
    [ast],
    false
  );

const buildPageJsxWithThemeProvider = (ast) =>
  t.jsxElement(
    t.jsxOpeningElement(
      t.jsxIdentifier("ThemeProvider"),
      [
        t.jsxAttribute(
          t.jsxIdentifier("theme"),
          t.jsxExpressionContainer(t.identifier("theme"))
        ),
      ],
      false
    ),
    t.jsxClosingElement(t.jsxIdentifier("ThemeProvider")),
    [ast],
    false
  );

export const generateBasicTemplate = (jsxAst, theme) => {
  const elementsInTree = findElementsInTree(generate(jsxAst).code);

  const { styles, ...sanitizedTheme } = theme;
  const themeAst = parseObject(sanitizedTheme);

  const gaudiElements = [
    ...grabGaudiElements(elementsInTree),
    "CurrentBreakpointProvider",
  ];

  const astsForTemplate = buildBasicTemplate({
    gaudiImports: t.importDeclaration(
      gaudiElements.map((el) =>
        t.importSpecifier(t.identifier(el), t.identifier(el))
      ),
      t.stringLiteral("@mknudsen01/superblock")
    ),
    theme: t.variableDeclaration("const", [
      t.variableDeclarator(t.identifier("theme"), themeAst),
    ]),
    jsx: buildPageJsxWithThemeProvider(
      buildPageJsxWithBreakpointProvider(jsxAst)
    ),
  });
  const generatedCode = astsForTemplate
    .map((ast) => generate(ast).code)
    .join("\n\n");
  const formatted = prettier.format(generatedCode, {
    parser: "babel",
    plugins: [parserBabel],
    printWidth: 120,
  });
  return formatted;
};
