import styled from "styled-components";

const Select = styled.select`
  width: 100%;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.gray[4]};
  border-radius: 5px;
  transition: 0.3s all ease;
  padding: 5px 0;
  &:focus {
    border-color: fuchsia;
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 255, 0.3);
  }
`;

export default Select;
