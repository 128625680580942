import React from "react";
import styled from "styled-components";
import { color, typography, space } from "styled-system";

const StyleLabel = styled.label`
  ${color}
  ${typography}
  ${space}
`;

const Label = (props) => (
  <StyleLabel fontWeight={[4]} pb={[1]} fontSize={[1]} {...props} />
);

export default Label;
