import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import StyledFooter from "./styles/UpdaterFooter";
import { NOT_DEFINED, UPDATER_INPUT_STATES } from "../redux/constants";
import {
  getActiveBreakpoint,
  getFocusedProp,
  getDefaultPropValuesForItem,
  getDefinedPropValuesForItem,
} from "../redux/selectors";
import {
  getValueForBreakpoint,
  isDefined,
  getUpdaterState,
} from "../lib/utils";
import {
  deleteDefaultStyle,
  resetItemProp,
  updateDefaultStyle,
  deleteItemProp,
} from "../redux/actions";

const ActionLink = styled.button`
  color: fuchsia;
  cursor: pointer;
  font-size: 12px;
  border-bottom: 1px solid transparent;
  padding: 3px 5px;
  transition: 0.3s all ease;
  &:disabled {
    color: #999;
    cursor: auto;
    pointer-events: none;
  }
`;

const DeleteDefaultLink = ({ isDisabled, onClick }) => (
  <ActionLink disabled={isDisabled} onClick={onClick}>
    Delete default
  </ActionLink>
);

const SetAsDefaultLink = ({ isDisabled, onClick }) => (
  <ActionLink disabled={isDisabled} onClick={onClick}>
    Set as default
  </ActionLink>
);

const ClearPropValueLink = ({ isDisabled, onClick }) => (
  <ActionLink disabled={isDisabled} onClick={onClick}>
    Clear
  </ActionLink>
);
const ResetToDefaultLink = ({ isDisabled, onClick }) => (
  <ActionLink disabled={isDisabled} onClick={onClick}>
    Reset to default
  </ActionLink>
);

function PropUpdaterInputActions({
  deleteDefaultStyle,
  currentValue,
  hasDefault,
  canDeleteDefault,
  isFocused,
  hasValue,
  name,
  breakpoint,
  deleteItemProp,
  resetItemProp,
  updateDefaultStyle,
  isResetToDefaultDisabled,
}) {
  const handleDeleteDefaultStyle = (e) => {
    deleteDefaultStyle({ propName: name, breakpoint });
  };

  const handleReset = (e) => {
    resetItemProp({
      propName: name,
      breakpoint,
    });
    e.target.blur();
  };

  const handleDelete = (e) => {
    deleteItemProp({
      propName: name,
      breakpoint,
    });
    e.target.blur();
  };

  const handleSetAsDefault = (e) => {
    updateDefaultStyle({ propName: name, breakpoint, value: currentValue });
    e.target.blur();
  };

  return (
    <StyledFooter isHidden={!isFocused}>
      {canDeleteDefault ? (
        <DeleteDefaultLink
          onClick={hasValue ? handleDeleteDefaultStyle : null}
          isDisabled={!hasValue || !isFocused}
        />
      ) : (
        <SetAsDefaultLink
          onClick={hasValue ? handleSetAsDefault : null}
          isDisabled={!hasValue || !isFocused}
        />
      )}
      {hasDefault ? (
        <ResetToDefaultLink
          isDisabled={!hasValue || !isFocused || isResetToDefaultDisabled}
          onClick={hasValue ? handleReset : null}
        />
      ) : (
        <ClearPropValueLink
          onClick={hasValue ? handleDelete : null}
          isDisabled={!hasValue || !isFocused}
        />
      )}
    </StyledFooter>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeBreakpoint = getActiveBreakpoint(state);
  const definedValues = getDefinedPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });
  const definedValue = isDefined(definedValues)
    ? getValueForBreakpoint(definedValues, activeBreakpoint)
    : null;
  const defaultValues = getDefaultPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });
  const defaultValue = isDefined(defaultValues)
    ? getValueForBreakpoint(defaultValues, activeBreakpoint)
    : null;

  const valueState = getUpdaterState({ definedValue, defaultValue });
  const canDeleteDefault =
    valueState === UPDATER_INPUT_STATES.USES_DEFAULT ||
    valueState === UPDATER_INPUT_STATES.MATCHES_DEFAULT;
  const currentValue = isDefined(definedValue)
    ? definedValue
    : isDefined(defaultValue)
    ? defaultValue
    : NOT_DEFINED;

  const disabledResetToDefaultStates = [
    UPDATER_INPUT_STATES.USES_DEFAULT,
    UPDATER_INPUT_STATES.MATCHES_DEFAULT,
  ];

  const isResetToDefaultDisabled = disabledResetToDefaultStates.includes(
    valueState
  );

  return {
    hasDefault: isDefined(defaultValue),
    hasValue: isDefined(currentValue),
    currentValue,
    canDeleteDefault,
    isFocused: getFocusedProp(state) === ownProps.name,
    breakpoint: activeBreakpoint,
    isResetToDefaultDisabled,
  };
};

export default connect(mapStateToProps, {
  deleteDefaultStyle,
  resetItemProp,
  updateDefaultStyle,
  deleteItemProp,
})(PropUpdaterInputActions);
