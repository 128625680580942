import defaultTheme from "../../defaultTheme";
import { UPDATE_DEFAULT_STYLE, DELETE_DEFAULT_STYLE } from "../actionTypes";

export default function (state = defaultTheme, action = {}) {
  switch (action.type) {
    case UPDATE_DEFAULT_STYLE: {
      const {
        propName,
        breakpoint,
        value,
        currentValues,
        type,
      } = action.payload;

      const newValues = currentValues.slice();
      newValues[breakpoint] = value;

      // run through the array to make sure there aren't empty values
      for (let i = 0; i < newValues.length; i++) {
        const value = newValues[i];
        if (typeof value === "undefined") {
          newValues[i] = null;
        }
      }

      return {
        ...state,
        styles: {
          ...state.styles,
          [type]: {
            ...state.styles[type],
            [propName]: newValues,
          },
        },
      };
    }
    case DELETE_DEFAULT_STYLE: {
      const { propName, breakpoint, type, currentValues } = action.payload;

      const newValues = [...currentValues];
      newValues[breakpoint] = null;

      return {
        ...state,
        styles: {
          ...state.styles,
          [type]: {
            ...state.styles[type],
            [propName]: newValues,
          },
        },
      };
    }
    default:
      return state;
  }
}
