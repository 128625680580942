import styled from "styled-components";

const StylesWhenEditable = styled.div`
  position: relative;
  transition: ${(props) => `${props.customHoverDuration || 0.3}s all ease`};
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10;
    transition: 0.3s all ease;
    box-shadow: ${(props) =>
      props.uid !== "root" && props.isCurrentEdit && "inset 0 0 0 2px fuchsia"};
  }
  &:hover {
    cursor: pointer;
    &::before {
      box-shadow: ${(props) =>
        props.uid !== "root" && "inset 0 0 0 2px fuchsia"};
    }
  }
`;

export const ImageWhenEditable = styled.div`
  position: relative;
  transition: 0.3s all ease;
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    transition: 0.3s all ease;
    box-shadow: ${(props) =>
      props.uid !== "root" && props.isCurrentEdit && "inset 0 0 0 2px fuchsia"};
  }
  &:hover {
    &::before {
      box-shadow: inset 0 0 0 2px fuchsia;
    }
  }
`;

export default StylesWhenEditable;
