import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { connect } from "react-redux";
import {
  setCurrentEdit,
  moveItem,
  moveItemToContainer,
} from "../redux/actions";
import { Flex } from "@mknudsen01/superblock";
import { TreeViewContext } from "./TreeView";
import ChevronRight from "./icons/ChevronRight";

const StyledTreeItem = styled.li`
  margin-left: ${(props) => `${props.level > 0 ? 1 : 0}em`};
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  box-shadow: ${(props) => {
    if (props.isBeingDraggedOver) {
      if (props.isAbove) {
        return "inset 0 2px 0 0 magenta";
      } else if (props.isBelow) {
        return "inset 0 -2px 0 0 magenta";
      }
    } else {
      return "none";
    }
  }};

  &:focus {
    outline: none;
    & > div {
      background-color: ${(props) => props.theme.colors["pink"][1]};
    }
  }
`;

const ExpandIcon = styled.div`
  transition: 0.3s all ease;
  transform: ${(props) => (props.isExpanded ? "rotate(90deg)" : "rotate(0)")};
`;

const TreeItemLabel = styled.div`
  color: ${(props) => (props.isActive ? "fuchsia" : "inherit")};
  font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  margin-left: ${(props) => (props.isExpandable ? 0 : "16px")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TreeItem = (props) => {
  const ref = useRef(null);
  const {
    children,
    label,
    uid,
    setCurrentEdit,
    level,
    onMoveToContainer,
    onMoveToItem,
  } = props;
  const {
    activeUid,
    expandSiblings,
    focusedUid,
    focusPreviousItem,
    focusParentItem,
    focusNextItem,
    focusItem,
    focusLastItem,
    focusFirstItem,
    focusSearchTerm,
    expandedItems,
    expandItem,
    collapseItem,
  } = React.useContext(TreeViewContext);

  const isActive = uid === activeUid;
  const isFocused = uid === focusedUid;
  const isExpandable = !!children;
  const isExpanded = expandedItems.includes(uid);

  const [{ isDragging }, drag] = useDrag({
    item: { type: isExpandable ? "CONTENT_PARENT" : "CONTENT_ITEM", uid },
    canDrag: (monitor) => uid !== "root",
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: ({ uid: sourceId }, monitor) => {
      const dropResult = monitor.getDropResult();
      if (!dropResult) return;
      const {
        uid: targetId,
        isBelow,
        canDropTargetAcceptChildren,
      } = dropResult;
      // const didDrop = monitor.didDrop();
      if (sourceId !== targetId) {
        if (canDropTargetAcceptChildren) {
          onMoveToContainer({ sourceId, targetId, isBelow });
        } else {
          onMoveToItem({ sourceId, targetId, isBelow });
        }
      }
    },
  });
  const [{ isOver, isAbove, isBelow }, drop] = useDrop({
    accept: ["CONTENT_ITEM", "CONTENT_PARENT"],
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        return {
          uid,
          item,
          isAbove,
          isBelow,
          canDropTargetAcceptChildren: false,
        };
      }
    },
    collect: (monitor) => {
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect() || {};

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset() || {};
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      return {
        isAbove: hoverClientY < hoverMiddleY,
        isBelow: hoverClientY >= hoverMiddleY,
        isOver: !!monitor.isOver({ shallow: true }),
      };
    },
  });

  useEffect(() => {
    if (isFocused) {
      ref.current.focus();
    }
  }, [isFocused]);

  const handleKeyDown = (event) => {
    const { key } = event;
    switch (key) {
      case "Enter":
      case " ":
        setCurrentEdit(uid);
        if (isExpandable && isExpanded && isActive) {
          collapseItem(uid);
        } else if (isExpandable && !isExpanded) {
          expandItem(uid);
        }
        event.preventDefault();
        event.stopPropagation();
        break;
      case "ArrowDown":
        event.preventDefault();
        focusNextItem();
        break;
      case "ArrowUp":
        event.preventDefault();
        focusPreviousItem();
        break;
      case "ArrowRight":
        event.preventDefault();
        event.stopPropagation();
        if (isExpandable && !isExpanded) {
          expandItem(uid);
          return;
        } else if (isExpandable && isExpanded) {
          focusNextItem();
          return;
        }

        break;
      case "ArrowLeft":
        event.preventDefault();
        event.stopPropagation();
        if (!!isExpandable && !!isExpanded) {
          collapseItem(uid);
          return;
        } else if (!isExpandable || !isExpanded) {
          focusParentItem(uid);
          return;
        }
        break;
      case "Home":
        focusFirstItem();
        break;
      case "End":
        focusLastItem();
        break;
      case "*":
        event.preventDefault();
        event.stopPropagation();
        expandSiblings(uid);
        return;
      default:
        if (key.match(/[a-zA-Z]/)) {
          focusSearchTerm(key);
        }
    }
  };

  drag(drop(ref));
  return (
    <StyledTreeItem
      ref={ref}
      role="treeitem"
      level={level}
      isDragging={isDragging}
      isAbove={isAbove}
      isBelow={isBelow}
      isBeingDraggedOver={isOver}
      tabIndex={isFocused ? 0 : -1}
      onKeyDown={handleKeyDown}
      aria-expanded={children ? isExpanded : null}
    >
      <Flex
        alignItems="center"
        onClick={() => {
          focusItem(uid);
          if (isExpandable && isExpanded && isActive) {
            collapseItem(uid);
          } else if (isExpandable && !isExpanded) {
            expandItem(uid);
          }
          setCurrentEdit(uid);
        }}
      >
        {isExpandable && (
          <ExpandIcon isExpanded={isExpanded}>
            <ChevronRight />
          </ExpandIcon>
        )}
        <TreeItemLabel isActive={isActive} isExpandable={isExpandable}>
          {label}
        </TreeItemLabel>
      </Flex>
      {children && isExpanded && <ul role="group">{children}</ul>}
    </StyledTreeItem>
  );
};

// const mapStateToProps = (state, ownProps) => {
//   return {
//     currentEdit: getUid(state),
//     content: getItemContent(state, { uid: ownProps.uid }),
//   };
// };

export default connect(null, {
  setCurrentEdit,
  onMoveToItem: moveItem,
  onMoveToContainer: moveItemToContainer,
})(TreeItem);
