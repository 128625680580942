import React, { useState } from "react";
import styled from "styled-components";
import ContentUpdaterSlider from "./ContentUpdaterSlider";

const MODES = {
  ALL: "ALL",
  AXES: "AXES",
  CORNERS: "CORNERS",
};

const Buttons = styled.div`
  display: flex;
  padding: 10px 0;
`;

const StyledButton = styled.button`
  background: ${(props) => (props.isActive ? "fuchsia" : "hotpink")};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding: 5px;
`;

function ContentUpdaterDirectional({ name }) {
  const [mode, setMode] = useState(MODES.ALL);
  return (
    <div>
      <Buttons>
        <StyledButton
          onClick={() => setMode(MODES.ALL)}
          id={MODES.ALL}
          isActive={mode === MODES.ALL}
        >
          All
        </StyledButton>
        <StyledButton
          onClick={() => setMode(MODES.AXES)}
          id={MODES.AXES}
          isActive={mode === MODES.AXES}
        >
          Axes
        </StyledButton>
        <StyledButton
          onClick={() => setMode(MODES.CORNERS)}
          id={MODES.CORNERS}
          isActive={mode === MODES.CORNERS}
        >
          Corners
        </StyledButton>
      </Buttons>
      {mode === MODES.ALL && (
        <>
          <ContentUpdaterSlider name={name} />
        </>
      )}
      {mode === MODES.AXES && (
        <>
          <ContentUpdaterSlider name={`${name}x`} />
          <ContentUpdaterSlider name={`${name}y`} />
        </>
      )}
      {mode === MODES.CORNERS && (
        <>
          <ContentUpdaterSlider name={`${name}t`} />
          <ContentUpdaterSlider name={`${name}b`} />
          <ContentUpdaterSlider name={`${name}l`} />
          <ContentUpdaterSlider name={`${name}r`} />
        </>
      )}
    </div>
  );
}

export default ContentUpdaterDirectional;
