import React from "react";
import { connect } from "react-redux";
import {
  PROPS_TO_THEME_KEY,
  PROPS_TO_DISPLAY_NAME,
  OPTIONS_BY_PROP,
} from "../redux/constants";
import { updateItemProp, setFocusedProp } from "../redux/actions";
import { getValueForBreakpoint } from "../lib/utils";
import {
  getCurrentPropValuesForItem,
  getOptionsForThemeKey,
  getActiveBreakpoint,
} from "../redux/selectors";
import Select from "./styles/Select";
import Label from "./styles/Label";
import PropUpdaterInputActions from "./PropUpdaterInputActions";

const ContentUpdaterSelect = ({
  uid,
  name,
  options,
  currentValue,
  updateItemProp,
  breakpoint = 0,
  setFocusedProp,
}) => {
  const handleChange = (e) => {
    const isEmpty = e.target.value === "";
    const newValue =
      isNaN(e.target.value) || isEmpty
        ? e.target.value
        : parseInt(e.target.value, 10);
    updateItemProp({
      value: isEmpty ? null : newValue,
      propName: name,
      breakpoint,
      uid,
    });
  };

  return (
    <>
      <Label htmlFor={`${name}-${breakpoint}`}>
        {PROPS_TO_DISPLAY_NAME[name] || name}
      </Label>
      <Select
        id={`${name}-${breakpoint}`}
        value={currentValue || ""}
        onFocus={() => {
          setFocusedProp(name);
        }}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            document.activeElement.blur();
          }
        }}
      >
        <option key="empty" value={""} />
        {options.map((opt) => {
          return (
            <option key={opt} value={opt}>
              {opt}
            </option>
          );
        })}
      </Select>

      <PropUpdaterInputActions name={name} uid={uid} />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const themeKey = PROPS_TO_THEME_KEY[ownProps.name];

  const activeBreakpoint = getActiveBreakpoint(state);

  const currentValues = getCurrentPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });

  const currentValue = getValueForBreakpoint(currentValues, activeBreakpoint);

  const options = themeKey
    ? getOptionsForThemeKey(state, { themeKey })
    : OPTIONS_BY_PROP[ownProps.name];

  return {
    options,
    currentValue,
    breakpoint: activeBreakpoint,
  };
};

export default connect(mapStateToProps, {
  updateItemProp,
  setFocusedProp,
})(ContentUpdaterSelect);
