import { NOT_DEFINED, UPDATER_INPUT_STATES } from "../redux/constants";

export const isDefined = (value) => {
  return (
    typeof value !== "undefined" && value !== null && value !== NOT_DEFINED
  );
};

// return true only for {} objects, not arrays, not functions
export const isObject = (value) => {
  let type = typeof value;
  return (
    type === "object" && !Array.isArray(value) && type !== "function" && !!value
  );
};

export const getValueForBreakpoint = (values, breakpoint) => {
  if (!Array.isArray(values)) {
    return values;
  }
  for (let i = breakpoint; i >= 0; i--) {
    if (isDefined(values[i])) {
      return values[i];
    }
  }
  return values[0];
};

export const getUpdaterState = ({ definedValue, defaultValue }) => {
  const hasDefinedValue = isDefined(definedValue);
  const hasDefaultValue = isDefined(defaultValue);

  if (!hasDefinedValue && !hasDefaultValue) {
    return UPDATER_INPUT_STATES.UNDEFINED_AND_NO_DEFAULT;
  }
  if (hasDefinedValue && !hasDefaultValue) {
    return UPDATER_INPUT_STATES.DEFINED_BUT_NO_DEFAULT;
  }
  if (!hasDefinedValue && hasDefaultValue) {
    return UPDATER_INPUT_STATES.USES_DEFAULT;
  }
  if (definedValue === defaultValue) {
    return UPDATER_INPUT_STATES.MATCHES_DEFAULT;
  } else {
    return UPDATER_INPUT_STATES.DIFFERS_FROM_DEFAULT;
  }
};

export const getBreakpointInPixels = (breakpoint) => {
  if (breakpoint.match("em")) {
    return parseInt(breakpoint.replace("em", ""), 10) * 16;
  }
  if (breakpoint.match("px")) {
    return parseInt(breakpoint.replace("px", ""), 10);
  }
  return breakpoint;
};

const getBreakpointsInPixels = (breakpoints) => {
  const pixelBreakpoints = breakpoints.map((b) => getBreakpointInPixels(b));
  return pixelBreakpoints;
};

export const getCurrentBreakpointFromWidth = (width, breakpoints) => {
  const pixelBreakpoints = getBreakpointsInPixels(breakpoints);
  const result = pixelBreakpoints.reduce(
    (activeBreakpoint, breakpointInPixels) => {
      if (width >= breakpointInPixels) {
        activeBreakpoint++;
      }
      return activeBreakpoint;
    },
    0
  );

  return Math.min(breakpoints.length - 1, result);
};

export const getRelativesForUid = ({ tree, uid }) => {
  let queue = ["root"];

  while (queue.length > 0) {
    const current = queue.shift();
    const { children } = tree[current];

    if (!Array.isArray(children)) {
      // text-only leaf node; won't be a parent, so skip it
      continue;
    }

    const matchIndex = children.indexOf(uid);
    if (matchIndex > -1) {
      // we have a match. return the parent and stop the loop
      return {
        parent: current,
        indexOfParent: matchIndex,
        previousSibling: matchIndex > 0 ? children[matchIndex - 1] : undefined,
        nextSibling: children[matchIndex + 1],
        children,
      };
    }

    queue = queue.concat(children);
  }

  return {};
};

export const getUidAndDescendantsForUid = ({ tree, uid }) => {
  let result = [];
  let queue = [uid];

  while (queue.length > 0) {
    const current = queue.shift();
    const { children } = tree[current];

    result.push(current);

    if (!Array.isArray(children)) {
      continue;
    }

    queue = queue.concat(children);
  }

  return result;
};

export const buildExpandedItemsToId = (data = {}, uid) => {
  let pathToActiveId = [];
  let foundPath = false;
  let attempts = 0;

  if (uid === "root") {
    return ["root"];
  }

  while (!foundPath) {
    attempts = attempts + 1;
    const nextUid = pathToActiveId.length
      ? pathToActiveId[pathToActiveId.length - 1]
      : uid;
    const { parent } = getRelativesForUid({ tree: data, uid: nextUid });
    pathToActiveId.push(parent);
    if (parent === "root" || attempts > 100) {
      if (attempts > 100) {
        console.log("Something went wonky", uid);
      }
      foundPath = true;
    }
  }

  return pathToActiveId;
};

/**
 * A Node for a tree item
 * @typedef {{uid: string, children: string|string[], type: string}} Node
 */

/**
 * @param {Object.<string, Node>} data
 * @param {string[]} expandedItems
 * @returns {Node[]}
 */
export const buildVisibleItems = (data = {}, expandedItems = ["root"]) => {
  let stack = ["root"];
  let result = [];

  while (stack.length > 0) {
    const current = stack.pop();
    if (!data[current]) continue;
    const { children, uid: currentUid, type } = data[current];

    result.push({ uid: currentUid, type, children });

    // if it's an expanded parent, add its children to the stack
    if (expandedItems.includes(currentUid) && Array.isArray(children)) {
      stack = stack.concat([...children].reverse()); // reverse so that first child is checked next
    }
  }

  return result;
};

export const saferGet = (input, key, defaultValue) => {
  let result = input;
  const keys = key && key.split ? key.split(".") : [key];
  for (let nextPathIndex = 0; nextPathIndex < keys.length; nextPathIndex++) {
    const nextPathItem = keys[nextPathIndex];
    result = result ? result[nextPathItem] : undefined;
  }

  return result === undefined ? defaultValue : result;
};
