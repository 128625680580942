export const CLEAR_CURRENT_EDIT = "CLEAR_CURRENT_EDIT";
export const SET_CURRENT_EDIT = "SET_CURRENT_EDIT";
export const UPDATE_TEXT = "UPDATE_TEXT";
export const UPDATE_ITEM_PROP = "UPDATE_ITEM_PROP";
export const RESET_ITEM_PROP = "RESET_ITEM_PROP";
export const UPDATE_DEFAULT_STYLE = "UPDATE_DEFAULT_STYLE";
export const DELETE_ITEM_PROP = "DELETE_ITEM_PROP";
export const DELETE_DEFAULT_STYLE = "DELETE_DEFAULT_STYLE";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const REORDER_SIBLINGS = "REORDER_SIBLINGS";
export const MOVE_ITEM = "MOVE_ITEM";
export const MOVE_ITEM_TO_CONTAINER = "MOVE_ITEM_TO_CONTAINER";
export const SET_VIEW_MODE = "SET_VIEW_MODE";
export const SET_PREVIEW_WIDTH = "SET_PREVIEW_WIDTH";
export const DELETE_ANIMATION = "DELETE_ANIMATION";
export const ADD_ANIMATION = "ADD_ANIMATION";
export const ADD_HOVER_PROP = "ADD_HOVER_PROP";
export const DELETE_HOVER_PROP = "DELETE_HOVER_PROP";
export const SET_FOCUSED_PROP = "SET_FOCUSED_PROP";
