import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { getUid, getItemContent } from "../redux/selectors";
import { Heading } from "@mknudsen01/superblock";
import ContentUpdaterController from "../components/ContentUpdaterController";
import { addHoverProp, deleteHoverProp } from "../redux/actions";
import { HOVER_PROPS } from "../redux/constants";

const Button = styled.button`
  font-weight: bold;
`;
const Delete = styled.button`
  background: white;
  margin: 16px 0;
  color: ${(props) => props.theme.colors["red"][5]};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors["red"][5]};
  border-radius: 5px;
  transition: 0.3s all ease;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  &:hover,
  &:focus {
    background: ${(props) => props.theme.colors["red"][5]};
    color: white;
  }
`;

function HoverController({ uid, hasHover, addHoverProp, deleteHoverProp }) {
  if (hasHover) {
    return (
      <>
        <Heading pt={8} letterSpacing={4} fontSize={2}>
          HOVER STYLES
        </Heading>
        {HOVER_PROPS.map((name) => {
          return <ContentUpdaterController key={name} name={name} />;
        })}
        <Delete onClick={() => deleteHoverProp({ uid })}>
          Remove hover styles
        </Delete>
      </>
    );
  }
  return (
    <Button onClick={() => addHoverProp({ uid })}>Add hover styles</Button>
  );
}

const mapStateToProps = (state) => {
  const uid = getUid(state);
  const content = getItemContent(state, { uid });
  const { props } = content;
  const hasHover = !!props.hover;
  return {
    uid,
    hasHover,
  };
};

export default connect(mapStateToProps, { addHoverProp, deleteHoverProp })(
  HoverController
);
