import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import PageContent from "./components/PageContent";
import "./App.css";

function App() {
  useEffect(() => {
    async function fetchData() {
      const response = await fetch("http://localhost:7777/api/");
      const json = await response.json();
      console.log(json);
    }
    fetchData();
  });

  return (
    <Provider store={store}>
      <PageContent />
    </Provider>
  );
}

export default App;
