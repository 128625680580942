import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  PROPS_TO_THEME_KEY,
  PROPS_TO_DISPLAY_NAME,
  OPTIONS_BY_PROP,
  PLACEHOLDERS_BY_PROP,
} from "../redux/constants";
import { updateItemProp, setFocusedProp } from "../redux/actions";
import { getValueForBreakpoint } from "../lib/utils";
import {
  getCurrentPropValuesForItem,
  getOptionsForThemeKey,
  getActiveBreakpoint,
  getFocusedProp,
} from "../redux/selectors";
import Input from "./styles/Input";
import Label from "./styles/Label";
import PropUpdaterInputActions from "./PropUpdaterInputActions";

const InputsContainer = styled.div`
  position: relative;
`;

const UnitContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
`;

const Select = styled.select`
  height: 100%;
  background: transparent;
  color: ${(props) => props.theme.colors.gray[7]};
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
  appearance: ${(props) => (props.disabled ? "none" : "auto")};
  padding-right: ${(props) => (props.disabled ? "5px" : 0)};
  border: 1px solid;
  border-radius: 5px;
  border-color: transparent;
  transition: 0.3s all ease;
  &:focus {
    outline: none;
    border-color: fuchsia;
    box-shadow: 0 0 0 3px rgba(255, 0, 255, 0.3);
  }
`;

const removeOptionsFromInput = (input, options) => {
  let result = `${input}` || "";
  options.forEach((option) => {
    result = result.replace(option.value, "");
  });
  return result;
};

function ContentUpdaterTextWithUnits({
  name,
  options,
  currentValue,
  updateItemProp,
  setFocusedProp,
  breakpoint,
  isFocused,
  uid,
}) {
  const currentAmount = removeOptionsFromInput(currentValue, options);
  const currentOption =
    options.find((option) => `${currentValue}`.match(option.value)) ||
    options[0];
  const currentUnit = currentOption.value;

  const handleChange = (value) => {
    updateItemProp({
      value,
      propName: name,
      breakpoint,
      uid,
    });
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    let newAmount;
    if (value.endsWith(".")) {
      // typing a decimal; don't parse the value just yet
      newAmount = value;
    } else {
      newAmount = parseFloat(e.target.value, 10);
      if (isNaN(newAmount)) {
        newAmount = 0;
      }
    }
    handleChange(`${newAmount}${currentUnit}`);
  };
  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    if (!newUnit) return; // somehow cleared the unit
    handleChange(`${currentAmount}${newUnit}`);
  };

  return (
    <div>
      <Label htmlFor={`${name}-${breakpoint}-amount`}>
        {PROPS_TO_DISPLAY_NAME[name]}
      </Label>
      <InputsContainer>
        <Input
          id={`${name}-${breakpoint}-amount`}
          autoComplete="off"
          placeholder={PLACEHOLDERS_BY_PROP[name]}
          onChange={handleAmountChange}
          onFocus={() => !isFocused && setFocusedProp(name)}
          value={currentAmount}
        />
        <UnitContainer>
          <Select
            id={`${name}-${breakpoint}-unit`}
            aria-label={name}
            aria-disabled={options.length === 1}
            disabled={options.length === 1}
            onChange={handleUnitChange}
            onFocus={() => !isFocused && setFocusedProp(name)}
            value={currentUnit}
          >
            {options.map((option) => {
              return (
                <option key={option.display} value={option.value}>
                  {option.display}
                </option>
              );
            })}
          </Select>
        </UnitContainer>
      </InputsContainer>
      <PropUpdaterInputActions name={name} uid={uid} />
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const themeKey = PROPS_TO_THEME_KEY[ownProps.name];
  const breakpoint = getActiveBreakpoint(state);
  const currentValues = getCurrentPropValuesForItem(state, {
    name: ownProps.name,
    uid: ownProps.uid,
  });

  const currentValue = getValueForBreakpoint(currentValues, breakpoint) || "";

  const isFocused = getFocusedProp(state) === ownProps.name;

  const options = themeKey
    ? getOptionsForThemeKey(state, { themeKey })
    : OPTIONS_BY_PROP[ownProps.name];

  return {
    options,
    currentValue,
    breakpoint,
    isFocused,
  };
};

export default connect(mapStateToProps, { updateItemProp, setFocusedProp })(
  ContentUpdaterTextWithUnits
);
