import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  CONTENT_TYPES,
  TYPOGRAPHY_PROPS,
  COLOR_PROPS,
  SPACE_PROPS,
  FLEX_PROPS,
  PROPS_BY_CONTENT_TYPE,
  SHADOW_PROPS,
  LAYOUT_PROPS,
  IMAGE_PROPS,
} from "../redux/constants";

import ContentUpdaterController from "../components/ContentUpdaterController";
import { Heading } from "@mknudsen01/superblock";
import { getUid, getItemContent } from "../redux/selectors";

const EditingPaneUpdaters = styled.div`
  display: flex;
  flex: 1 1;
  flex-direction: column;
`;

const PropUpdatersContainer = ({ content }) => {
  const { type, children } = content;
  const isTextElement = !Array.isArray(children);
  const isHeading = type === CONTENT_TYPES.HEADING;
  const propsForType = PROPS_BY_CONTENT_TYPE[type];

  const hasTypographyProp =
    propsForType &&
    TYPOGRAPHY_PROPS.some((prop) => propsForType.includes(prop));
  const hasColorProp =
    propsForType && COLOR_PROPS.some((prop) => propsForType.includes(prop));
  const hasSpaceProp =
    propsForType && SPACE_PROPS.some((prop) => propsForType.includes(prop));
  const hasFlexProp =
    propsForType && FLEX_PROPS.some((prop) => propsForType.includes(prop));
  const hasShadowProp =
    propsForType && SHADOW_PROPS.some((prop) => propsForType.includes(prop));
  const hasLayoutProp =
    propsForType && LAYOUT_PROPS.some((prop) => propsForType.includes(prop));
  const hasImageProp =
    propsForType && IMAGE_PROPS.some((prop) => propsForType.includes(prop));

  return (
    <EditingPaneUpdaters>
      {isTextElement && <ContentUpdaterController name="children" />}
      {/**
       * TODO: will need to list these in more programatic way.
       * Use the element type to figure out which props to show
       *  */}

      {isTextElement && (
        <>
          {!!isHeading && <ContentUpdaterController name="headingLevel" />}
          {hasTypographyProp && (
            <>
              <Heading pt={8} letterSpacing={4} fontSize={2}>
                TYPOGRAPHY
              </Heading>
              {TYPOGRAPHY_PROPS.map((name) => {
                return (
                  propsForType.includes(name) && (
                    <ContentUpdaterController key={name} name={name} />
                  )
                );
              })}
            </>
          )}
        </>
      )}
      {hasLayoutProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            LAYOUT
          </Heading>
          {LAYOUT_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
      {hasColorProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            COLOR
          </Heading>
          {COLOR_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
      {hasFlexProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            LAYOUT
          </Heading>
          {FLEX_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
      {hasImageProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            IMAGE
          </Heading>
          {IMAGE_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
      {hasSpaceProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            SPACING
          </Heading>
          {SPACE_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
      {hasShadowProp && (
        <>
          <Heading pt={8} letterSpacing={4} fontSize={2}>
            SHADOW
          </Heading>
          {SHADOW_PROPS.map((name) => {
            return (
              propsForType.includes(name) && (
                <ContentUpdaterController key={name} name={name} />
              )
            );
          })}
        </>
      )}
    </EditingPaneUpdaters>
  );
};

const mapStateToProps = (state) => {
  const uid = getUid(state);
  const content = getItemContent(state, { uid });

  return {
    uid,
    content,
  };
};

export default connect(mapStateToProps, {})(PropUpdatersContainer);
