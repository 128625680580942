import {
  SET_CURRENT_EDIT,
  CLEAR_CURRENT_EDIT,
  ADD_HOVER_PROP,
  DELETE_HOVER_PROP,
  ADD_ITEM,
  DELETE_ITEM,
  UPDATE_TEXT,
  UPDATE_ITEM_PROP,
  RESET_ITEM_PROP,
  UPDATE_DEFAULT_STYLE,
  DELETE_ITEM_PROP,
  DELETE_DEFAULT_STYLE,
  REORDER_SIBLINGS,
  MOVE_ITEM,
  MOVE_ITEM_TO_CONTAINER,
  SET_VIEW_MODE,
  SET_PREVIEW_WIDTH,
  DELETE_ANIMATION,
  ADD_ANIMATION,
  SET_FOCUSED_PROP,
} from "./actionTypes";
import {
  getCurrentPropValuesForItem,
  getDefaultPropValuesForItem,
  getUid,
  getCurrentItemContent,
  getThemeDefaultStylesForType,
  getContent,
  getRelatives,
  getUidAndDescendants,
} from "./selectors";
import { createItem } from "../lib/itemFactory";
import { MOVE_DIRECTIONS, CONTENT_TYPES } from "./constants";

export const clearCurrentEdit = () => ({
  type: CLEAR_CURRENT_EDIT,
  payload: {},
});

export const setCurrentEdit = (uid) => ({
  type: SET_CURRENT_EDIT,
  payload: {
    uid,
  },
});

export const updateText = ({ text, uid }) => ({
  type: UPDATE_TEXT,
  payload: {
    uid,
    text,
  },
});

export const resetItemProp = ({ propName, breakpoint }) => {
  return (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);

    const currentValues = getCurrentPropValuesForItem(state, {
      name: propName,
    });

    const defaultValues = getDefaultPropValuesForItem(state, {
      name: propName,
    });
    const value = defaultValues[breakpoint];

    return dispatch({
      type: RESET_ITEM_PROP,
      payload: {
        uid,
        propName,
        breakpoint,
        value,
        currentValues,
      },
    });
  };
};

export const updateItemProp = ({ value, propName, breakpoint, uid }) => {
  return (dispatch, getState) => {
    const state = getState();
    const itemUid = uid || getUid(state);

    const currentValues = getCurrentPropValuesForItem(state, {
      name: propName,
      uid: itemUid,
    });

    return dispatch({
      type: UPDATE_ITEM_PROP,
      payload: {
        uid: itemUid,
        propName: propName,
        breakpoint,
        value,
        currentValues,
      },
    });
  };
};

export const deleteItemProp = ({ propName, breakpoint }) => {
  return (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);

    const currentValues = getCurrentPropValuesForItem(state, {
      name: propName,
    });

    return dispatch({
      type: DELETE_ITEM_PROP,
      payload: {
        uid,
        propName: propName,
        breakpoint,
        currentValues,
      },
    });
  };
};

export const updateDefaultStyle = ({ value, propName, breakpoint }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { type } = getCurrentItemContent(state);
    const defaultStyles = getThemeDefaultStylesForType(state, { type });
    const currentValues = defaultStyles[propName] || [];

    return dispatch({
      type: UPDATE_DEFAULT_STYLE,
      payload: {
        value,
        propName,
        breakpoint,
        currentValues,
        type,
      },
    });
  };
};

export const deleteDefaultStyle = ({ propName, breakpoint }) => {
  return (dispatch, getState) => {
    const state = getState();
    const { type } = getCurrentItemContent(state);
    const defaultStyles = getThemeDefaultStylesForType(state, { type });
    const currentValues = defaultStyles[propName] || [];

    dispatch({
      type: DELETE_DEFAULT_STYLE,
      payload: {
        propName,
        breakpoint,
        currentValues,
        type,
      },
    });
  };
};

export const addItem = ({ type }) => {
  return async (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);
    const newItem = createItem({ type });
    const { uid: newUid } = newItem;

    await dispatch({
      type: ADD_ITEM,
      payload: {
        parentId: uid,
        newItem,
      },
    });

    return dispatch(setCurrentEdit(newUid));
  };
};

export const deleteItem = () => {
  return (dispatch, getState) => {
    const state = getState();
    const uid = getUid(state);

    const { parent: parentId } = getRelatives(state);
    const uidAndDescendants = getUidAndDescendants(state);

    return dispatch({
      type: DELETE_ITEM,
      payload: {
        uid,
        parentId,
        idsToDelete: uidAndDescendants,
      },
    });
  };
};

const reorderSibling = ({ direction }) => {
  // direction of left, right, up or down
  // left == move before prev sibling
  // right ==  move after next sibling

  return (dispatch, getState) => {
    let destinationIndex;
    const state = getState();
    const uid = getUid(state);
    const content = getContent(state);
    const { parent: parentId } = getRelatives(state);

    const parent = content[parentId];
    const { children: siblings } = parent;

    const sourceIndex = siblings.findIndex((childId) => childId === uid);

    if (direction === MOVE_DIRECTIONS.LEFT) {
      destinationIndex = sourceIndex - 1;
    } else if (direction === MOVE_DIRECTIONS.RIGHT) {
      destinationIndex = sourceIndex + 1;
    } else {
      // some error; don't do anything to the state
      console.error(`${direction} is unsupported`);
      return state;
    }

    return dispatch({
      type: REORDER_SIBLINGS,
      payload: {
        uid,
        sourceIndex,
        destinationIndex,
        parentId,
      },
    });
  };
};

export const moveSiblingLeft = () =>
  reorderSibling({ direction: MOVE_DIRECTIONS.LEFT });
export const moveSiblingRight = () =>
  reorderSibling({ direction: MOVE_DIRECTIONS.RIGHT });

export const moveItem = ({ targetId, sourceId, isBelow }) => {
  return {
    type: MOVE_ITEM,
    payload: {
      targetId,
      sourceId,
      isBelow,
    },
  };
};

export const moveItemToContainer = ({ targetId, sourceId }) => {
  return {
    type: MOVE_ITEM_TO_CONTAINER,
    payload: {
      targetId,
      sourceId,
    },
  };
};

export const setViewMode = (mode) => {
  return {
    type: SET_VIEW_MODE,
    payload: {
      mode,
    },
  };
};

export const setPreviewWidth = (width) => {
  return {
    type: SET_PREVIEW_WIDTH,
    payload: {
      width,
    },
  };
};

export const deleteAnimation = ({ animationId, parentId }) => {
  return {
    type: DELETE_ANIMATION,
    payload: {
      animationId, // don't need this now, but will once use array of animations
      parentId,
    },
  };
};

export const addAnimation = ({ parentId }) => {
  const animation = createItem({ type: CONTENT_TYPES.IN_VIEW_ANIMATION });
  return {
    type: ADD_ANIMATION,
    payload: {
      parentId,
      animation,
    },
  };
};

export const addHoverProp = ({ uid }) => {
  return {
    type: ADD_HOVER_PROP,
    payload: {
      uid,
    },
  };
};

export const deleteHoverProp = ({ uid }) => {
  return {
    type: DELETE_HOVER_PROP,
    payload: {
      uid,
    },
  };
};

export const setFocusedProp = (name) => {
  return {
    type: SET_FOCUSED_PROP,
    payload: {
      name,
    },
  };
};
