import { MIN_WIDTH } from "../constants";
import { SET_PREVIEW_WIDTH } from "../actionTypes";

export default function (state = MIN_WIDTH, action = {}) {
  switch (action.type) {
    case SET_PREVIEW_WIDTH: {
      const { width } = action.payload;

      return width;
    }
    default:
      return state;
  }
}
