import { EDITOR_MODES } from "../constants";
import { SET_VIEW_MODE } from "../actionTypes";

export default function (state = EDITOR_MODES.EDITING, action = {}) {
  switch (action.type) {
    case SET_VIEW_MODE: {
      const { mode } = action.payload;

      return mode;
    }
    default:
      return state;
  }
}
