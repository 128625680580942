import React from "react";
import ContentItem from "./ContentItem";

const ContentList = ({ childIds }) => {
  return (
    <>
      {childIds.map((childId) => {
        return <ContentItem key={childId} uid={childId} />;
      })}
    </>
  );
};

export default ContentList;
